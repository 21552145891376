import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { Card, DatePicker, message, Empty } from 'antd';
import './InventoryDashboardStyle.css'
import { GetInventoryDashboradData } from '../../_services/InventoryService'
import moment from 'moment';
import { DateStringFormat, convToAPIFormat } from '../../_helper/dateUtil';
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../_redux/loader/action.js';
import {isBrowser, isMobile } from 'react-device-detect';
import { FiMoreVertical } from "react-icons/fi";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

 function InventoryDashboard(props) {

    const { RangePicker } = DatePicker;
    const [data, setDashboardData] = useState([]);
    const [widgetdata, setWidgetdata] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('');
    const [activeState, activesetStatus] = useState('Month');
    const [showBtns, setShowBtns] = useState(false);
    const [dateFormat, setdateFormat] = useState(null);
    const [headerFilterOpen,setHeaderFilterOpen] = useState(false)
    const [dateForm, setdateForm] = useState(localStorage.getItem('dateFormat'));
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(30);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const config = {
        data,
        xField: 'itemSKU',
        yField: 'quantity',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
            },
        },
        autoFit: true,
        meta: {
            type: {
                alias: 'category',
            },
            sales: {
                alias: 'sales',
            },
        },
    };

    useEffect(()=>{
        var formatDate;
        if(dateForm === "1"){
            formatDate = 'MM-DD-YYYY';
        }
        else{
            formatDate = 'DD-MM-YYYY';
        } 
        setdateFormat(formatDate);
    },[])

    useEffect(() => {
    }, [data])

    useEffect(() => {
        if(dateFormat !== null){
            getDates("Month");
        }
    }, [dateFormat])

    useEffect(() => {
        if (startDate && endDate && dateFormat !== null) {
            InventoryDashboradData();
        }
    }, [startDate, endDate])

    const InventoryDashboradData = () => {
        let obj = {
            OrgCode: localStorage.getItem('OrgCode'),
            OrgBranchCode: localStorage.getItem('LocCode'),
            StartDate: convToAPIFormat(startDate),
            EndDate: convToAPIFormat(endDate, "End")  
            // for enddate add this end, so that it adds 1 extra day to fetch data
        }
        props.showLoaderAsync(true);
        GetInventoryDashboradData(obj).then(responce => {
            props.hideLoaderAsync(false);
            if (responce.data.success) {                
                responce.data.details.forEach((x,id)=>{
                    x.total = parseFloat(parseFloat(x.total))
                    x.dashboardViewList && x.dashboardViewList.forEach((y)=>{
                        y.quantity = parseFloat(parseFloat(y.quantity))
                    })                    
                })
                setWidgetdata(responce.data.details);
                loadDashboad(responce.data.details[0])
              //  setDashboardData()
            } else {
                message.error(responce.data.message);
            }
        })
    }

    const loadDashboad = (item) => {
        let headers = item.dashboardViewList.map(x => x.itemSKU);
        if (item.title === "Sale Orders (Quantity)") {
            setShowBtns(true)
        } else {
            setShowBtns(false)
        }
        let uniqueValues = headers.filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        let finalObject = [];
        uniqueValues.forEach((value, i) => {
            let dataObj = item.dashboardViewList.filter(x => x.itemSKU === value);
            if (dataObj.length === 1) {
                finalObject.push(dataObj[0]);
            }
            else if (dataObj.length > 1) {
                let TotalSum = dataObj.reduce((previousValue, currentValue) => {
                    return parseFloat(previousValue) + parseFloat(currentValue.quantity)
                }, 0);
                let NewData = JSON.parse((JSON.stringify(dataObj[0])));
                NewData.quantity = TotalSum;
                finalObject.push(NewData);
            }
        })
        setDashboardData(finalObject);
    }

    const onPickerHandler = (item, value, type) => {       
        if (value[0] !== '' || value[1] !== '') {     
            let d1 = changeDateFormat(value[0])       
            let d2 = changeDateFormat(value[1])       
            // if (d2.getTime() <= d1.getTime()) {
            if ((Date.parse(d1) <= Date.parse(d2))) {
                setStartDate(value[0]);
                setEndDate(value[1]);
                setStatus("Custom Date");
            }
            else {
                alert("End Date should be greater than Start Date")
            }
        }
    }

    const changeDateFormat = (value)=>{
        let spArr = value.split("-");
        if (dateForm !== "1") {
            value = [spArr[1], spArr[0], spArr[2]].join("-");
        } 
        return new Date(value);
    }

    const getDates = (type) => {
        activesetStatus(type);
        if (type === "Day") {
            setStartDate(DateStringFormat(new Date()));
            setEndDate(DateStringFormat(new Date()));
            setStatus("");
        } else if (type === "Week") {
            let getMonth = moment().subtract(0, "week").startOf("week").format(dateFormat);
            let EndMonth = moment().subtract(0, "week").endOf("week").format(dateFormat);
            setStartDate(getMonth);
            setEndDate(EndMonth);
            setStatus("This Week");
        } else if (type === "Month") {
            let getMonth = moment().subtract(0, "month").startOf("month").format(dateFormat);
            let EndMonth = moment().subtract(0, "month").endOf("month").format(dateFormat);
            setStartDate(getMonth);
            setEndDate(EndMonth);
            setStatus("This Month");
        } else if (type === "Year") {            
            let FinStart = new Date(new Date().getFullYear(), 3, 1);
            let FinEnd = new Date(new Date().getFullYear()+1, 2, 31);
            setStartDate(DateStringFormat(FinStart));
            setEndDate(DateStringFormat(FinEnd));            
            setStatus("This Year");
        }
    }

    const onSellingItemsHandler = (type) => {
        if (type === "fast") {
        } else if (type === "slow") {
        }
    }
    const HeaderFilters=()=>{
        return(
            <div className="inventroryActionIcons" style={isMobile?{display:'grid',gridTemplateColumns:'auto',gap:'5px',width:'50%',background:'#fff',marginTop:'0px',zIndex:1000}:{}}>
            <button onClick={() => getDates("Day")} className={activeState === 'Day' ? 'invActive' : ''}>Day</button>
            <button onClick={() => getDates("Week")} className={activeState === 'Week' ? 'invActive' : ''}>Week</button>
            <button onClick={() => getDates("Month")} className={activeState === 'Month' ? 'invActive' : ''}>Month</button>
            <button onClick={() => getDates("Year")} className={activeState === 'Year' ? 'invActive' : ''}>Year</button>
            <RangePicker 
                format={dateFormat} 
                allowClear={true}
                // onCalendarChange={(e, i, a) => onPickerHandler(e, i, a)} 
                onChange={(e, i, a) => onPickerHandler(e, i, a)} 
                value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]} 
            />
        </div>
        )
    }

    const onPagePrev = () => {
        setCurrentPage(currentPage - 1);
    };

    const onPageNext = () => {
        setCurrentPage(currentPage +  1);
    };

    const updatedConfig = {
        ...config,
        data: currentItems,
    };

  return (
        <section>
            {
                isMobile ? (
                    <div className="inventroryActionIcons">
                        <FiMoreVertical onClick={()=>setHeaderFilterOpen(!headerFilterOpen)} />
                    </div>
                ) : (<HeaderFilters />)
            }
          {headerFilterOpen && <HeaderFilters/>}
            <div className="row" style={{ margin: "0 -15px" }}>
                {widgetdata && widgetdata.map(item => {
                    return (
                        <div className="col-md-4" onClick={() => loadDashboad(item)}>
                            <Card style={{ cursor: "pointer" }}>
                                <h5>{item.title}</h5>
                                <div className="card-widget">
                                    <img  width={45} height={45} src={item.title === "Inventory (Quantity)" ? "/images/inventory1.png" 
                                    : item.title === "Sale Orders (Quantity)" ? "/images/order.png" :
                                        item.title === "Sale Invoices (Quantity)" ? "/images/invoice.png" : ''} alt="" />
                                    <div>
                                        <h4>{ item.total}</h4>
                                        {status !== '' ?
                                            <span><i className="fas fa-calendar-alt"></i> {status}</span> : ''}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                })}
            </div>
            {data && data.length > 0 ?
                <div className="tableSearchPosition mt-3">
                    <div className="dashboardheader px-2 pt-1">
                    <h3>Sales Overview</h3>
                    <div style={{marginLeft:"auto"}}>
                    <button disabled={currentPage === 1} onClick={() => onPagePrev()} style={{borderStyle:"none"}}><LeftOutlined/></button>
                    <span> Page {currentPage} of {Math.ceil(data.length / itemsPerPage)}
                    <button disabled={indexOfLastItem >= data.length} onClick={() => onPageNext()} style={{borderStyle:"none"}}><RightOutlined/></button>
                    </span>
                    </div>
                        <div>
                            {showBtns ?
                                <>  <button onClick={() => onSellingItemsHandler("fast")}>Fast Selling Items</button>
                                    <button onClick={() => onSellingItemsHandler("slow")}>Slow Selling Items</button>
                                </> : ''
                            }
                        </div>
                    </div>
                    <section className="p-2 dashFixed">
                        <Column {...updatedConfig} />
                    </section>
                </div>
                :
                <div className="tableSearchPosition mt-3">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-5 py-5"
                        description={
                            <span className="text-center">
                                <b>No Data</b><br />
                            </span>
                        }
                    />
                </div>
            }
        </section>
    )
}
const mapStateToProps = ({ loader_reducer, breadCrumb_reducer }) => {
    return { loader_reducer, breadCrumb_reducer }
}
const mapDispatchToProps = { showLoaderAsync: showLoader, hideLoaderAsync: hideLoader };

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDashboard);