import React, {Component} from 'react';
import {login} from './configOidc';
import { BrowserRouter as Router, Route} from 'react-router-dom';

class Authentication extends Component {

    state= {
        firstRender : true,
    }

    componentDidMount(){
        if(this.state.firstRender === true){
          login();
          this.setState({firstRender : false})
        }
    }

    render() { 
        return (
            <Router>
              <Route exact path="/callback" render={() => {window.location.href="http://192.168.2.121:93/"}} />
            </Router>
        );
    }
  }

export default Authentication