import moment from "moment";

export const DateStringFormat = (date) => {
    const dateForm = localStorage.getItem('dateFormat')
    if (date !== null && date !== undefined) {
        date = new Date(date);
        let day, month, year, formatDate;
        day = date.getDate().toString();
        if (day.length < 2) day = `0${day}`
        month = date.getMonth() + 1;
        month = month.toString();
        if (month.length < 2) month = `0${month}`;
        year = date.getFullYear().toString();        
        if (dateForm === '1') {
            formatDate = month + '-' + day + '-' + year;
        }
        else if (dateForm === '2') {
            formatDate = day + '-' + month + '-' + year;
        }
        else {
            formatDate = day + '-' + month + '-' + year;
        }
        return !date ? '' : formatDate;
    }
};

// to convert to "dd-mm-yyyy" format
export const DateStringFormatNew = (date) => {
    if (date !== null && date !== undefined) {
        date = new Date(date);
        let day, month, year, formatDate;
        day = date.getDate().toString();
        if (day.length < 2) day = `0${day}`
        month = date.getMonth() + 1;
        month = month.toString();
        if (month.length < 2) month = `0${month}`;
        year = date.getFullYear().toString();
        formatDate = day + '-' + month + '-' + year;
        return !date ? '' : formatDate;
    }
};

export const DateFormatMMDDYY = (date) => {
    if (date !== null && date !== undefined) {
        date = new Date(date);
        let day, month, year, formatDate;
        day = date.getDate().toString();
        if (day.length < 2) day = `0${day}`
        month = date.getMonth() + 1;
        month = month.toString();
        if (month.length < 2) month = `0${month}`;
        year = date.getFullYear().toString();
        formatDate = month + '-' + day + '-' + year;
        return !date ? '' : formatDate;
    }
};

// to convert to "yyyy-mm-dd" format
export const DateFormatYmd = (date) => {
    if (date !== null && date !== undefined) {
        date = new Date(date);
        let day, month, year, formatDate;
        day = date.getDate().toString();
        if (day.length < 2) day = `0${day}`
        month = date.getMonth() + 1;
        month = month.toString();
        if (month.length < 2) month = `0${month}`;
        year = date.getFullYear().toString();
        formatDate = year + '-' + month + '-' + day;
        return !date ? '' : formatDate;
    }
};

// convert to standard API format MM-DD-YYYY 
export const convToAPIFormat = (dateObj, dateType)=>{
    const dateForm = localStorage.getItem('dateFormat')
    var formatDate ;
    if(dateForm === "1"){
        formatDate = 'MM-DD-YYYY';
    }
    else{
        formatDate = 'DD-MM-YYYY';
    } 
    if(dateType === 'End'){
        dateObj = moment(dateObj, formatDate).add(1, 'days').format(formatDate);
        console.log(dateObj)
    }

    let newFormatDate = dateObj;
    let splitDate = dateObj.split('-')
    if (dateForm === '2') {
        newFormatDate = splitDate[1] + '-' + splitDate[0] + '-' + splitDate[2];
    }  
    return  newFormatDate;
}

export const formateDateTime = (e) => {
    if (e) {
        let dateTime = e.split('T');
        let date = dateTime[0].split('-')
        let formateDate = date[2] + '-' + date[1] + '-' + date[0];
        return formateDate;
    }
}

export const splitDate = (e) => {
    if (e) {
        let dateTime = e.split('T');
        let splitedDate = e.split(' ');
        if (dateTime.length > 1) {
            return dateTime[0]
        }
        else if (splitedDate.length > 1) {
            return splitedDate[0];
        }
    }
}

export const getTimeOnly = (e) => {
    if (e) {
        let totalLength = (e.length - 1)
        let time = e.substr(11, totalLength)
        return time;
    }
}

export const TimeDateFormat = (e) => {
    if (e) {
        let dateTime = e.split('T');
        let date = dateTime[0].split('-')
        let dTime = dateTime[1].split(':')
        let formateDate = date[2] + '-' + date[1] + '-' + date[0] + " " + dTime[0] + ':' + dTime[1];
        return formateDate;
    }
}


export const DisplayCurrentTime = (dateObj) => {
    let date = new Date(new Date(dateObj));
    let timeObj = displayCuttentDate(date)
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let time = timeObj + ' ' + hours + ":" + minutes + " " + am_pm;
    return time;
};

export const displayCuttentDate = (dateObj) => {
    let date = new Date(new Date(dateObj));
    var day = date.getDate();
    var month = monthData[date.getMonth()];
    var fullYear = date.getFullYear();
    let time = day + ' ' + month + ' ' + fullYear;
    return time
}

const monthData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


export const changeDateFormat = (value) => {
    const dateForm = localStorage.getItem('dateFormat')
    let spArr = value.split("-");
    if (dateForm !== "1") {
        value = [spArr[1], spArr[0], spArr[2]].join("-");
    } 
    return new Date(value);
}

export const dateFormatVal = (value) => {  
    const dateForm = localStorage.getItem('dateFormat')
    let date = DateStringFormat(value);
    let spArr = date.split("-");
    if (dateForm === "1") {
        date = [spArr[2], spArr[0], spArr[1]].join("-");
    } else if (dateForm === "2") {
        date = [spArr[2], spArr[1], spArr[0]].join("-");
    }            
    return new Date(date)
}
