import React, { Suspense } from "react"
import { Spin } from 'antd';

const HigherOrderComponent = (WrappedComponent) => {

    return (props) => (<Suspense fallback={<div className="spinLoaderHigh"><Spin size="large" /> </div>}>
        <WrappedComponent {...props} />
    </Suspense>)

}
export default HigherOrderComponent