import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { LEDG_TRANS_LIST_REQUEST, ORGLOGOAPI_REQUEST, ORGLOGOSAVEAPI_REQUEST, 
ORG_GENCONFIGAPI_REQUEST, SAVE_GENCONFIGAPI_REQUEST, TERMSCONDISLIST_REQUEST, 
} from "../actions";
import { LedgTrans_Request_Success, orgLogoApi_success, saveConfigApi_success, 
termsCond_Request_Success  } from "./action";
import { SaveGenConfig, getGenConfig, getOrgLogo, saveOrgLogo } from "../../_services/GenConfigService";
import { GetTermsAndConditionsList } from "../../_services/DocNumService";
import { getLedgerEffectList } from "../../_services/AccountService";
  
export function* watchOrgLOGO() {
  yield takeLatest(ORGLOGOAPI_REQUEST, getORGLogoData);
}

export function* watchSaveOrgLOGO() {
  yield takeLatest(ORGLOGOSAVEAPI_REQUEST, saveORGLogo);
}

// API Function
const fetchDataFromAPI = async()=> {
  let obj ={OrgCode : localStorage.getItem('OrgCode')}
  let byteArray = ''
  await getOrgLogo(obj).then((response)=>{
      if(response.status === 200){
        if(response.data.success){
          byteArray = response.data.details
        }          
      }
  })      
  // Replace with your API endpoint
  return byteArray 
}

const postDataFromAPI = async(payload)=> {
  let byteArray = ''  
  await saveOrgLogo(payload).then((response)=>{
    if(response.status === 200){
      byteArray = payload.logo
    }
  })
  return byteArray 
}

function* getORGLogoData({ payload }) {
  try {
    const response = yield call(fetchDataFromAPI);
    yield put(orgLogoApi_success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

function* saveORGLogo({ payload }) {
  try {
    const response = yield call(postDataFromAPI(payload) );
    yield put(orgLogoApi_success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

// FOR ORG-GENERAL CONFIG
export function* watchGeneralConfig() {
  yield takeLatest(ORG_GENCONFIGAPI_REQUEST, getGeneralConfig);
}

export function* watchSaveGeneralConfig() {
  yield takeLatest(SAVE_GENCONFIGAPI_REQUEST, saveConfigData);
}
//GENCONFIGAPI_SUCCESS
function* getGeneralConfig({ payload }) {
  try {
    const response = yield call(fetchGenConfigDataAPI);
    yield put(saveConfigApi_success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

function* saveConfigData({ payload }) {
  try {
    const response = yield call(saveConfigDataAPI(payload) );
    yield put(saveConfigApi_success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

// API Function
const fetchGenConfigDataAPI = async()=> {
  let Obj = {}
  Obj.OrgCode = localStorage.getItem('OrgCode');
  let configData = {} 
  await getGenConfig(Obj).then(async (response) => {
    if (response) {
      configData = response.data.result.details
      configData = configData.length > 0 ? configData[0] : {}
    }
  }) 
  // Replace with your API endpoint
  return configData 
}

const saveConfigDataAPI = async(payload)=> {
  let resultData = payload  
  await SaveGenConfig(payload).then(response => {
    if (response.status === 200) {
      this.setState({ showApp: false })
    }
  })
  return resultData 
}

//  for terms&Conditions use this calls
export function* watchTermsConditionsList() {
  yield takeLatest(TERMSCONDISLIST_REQUEST, getListOfTerms);
}

// GET TermsList Call
function* getListOfTerms({ payload }) {
  try {
    const response = yield call(fetchTermsConditionsList);
    yield put(termsCond_Request_Success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

// API Function
const fetchTermsConditionsList = async()=> {
  let Obj = {}
  Obj.orgCode = localStorage.getItem('OrgCode');
  let termsData = []
  await GetTermsAndConditionsList(Obj).then((response) => {
    if(response.status === 200){
      termsData = response.data.result.details;
    }      
  }) 
  // Replace with your API endpoint
  return termsData 
}
// end of terms&Conditions use this calls

//  for terms&Conditions use this calls
  
export function* watchLedgTransLst() {
  yield takeLatest(LEDG_TRANS_LIST_REQUEST, getLedgTransLst);
}

// GET TermsList Call
function* getLedgTransLst({ payload }) {
  try {
    const response = yield call(fetchLedgTranEffectLst);
    yield put(LedgTrans_Request_Success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

const fetchLedgTranEffectLst = async() => {
    let ledgTransData = []
    let Obj = {
      OrgCode: localStorage.getItem('OrgCode'),
    }
    await getLedgerEffectList(Obj).then((response) => {
      if(response.status === 200){
        let data = response.data.result.details     
        ledgTransData = data.length > 0 ? data : []
      }
    })
    return ledgTransData
  }

export default function* rootSaga() {
    yield all([
      fork(watchOrgLOGO),
      fork(watchSaveOrgLOGO),
      fork(watchGeneralConfig),
      fork(watchSaveGeneralConfig),
      fork(watchTermsConditionsList),
      fork(watchLedgTransLst),
    ]);
  }
  

 