import { SHOWLOADER, HIDELOADER, UPDATEMANAGEITEM, LOCATIONAPICALL, NOTIFICATIONAPI } from "../actions";

const initialState = {
  loader: false,
  updateManageItem: '',
  locationApiCall: false,
  notificationApi:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOWLOADER:
      return { ...state, loader: true };
    case HIDELOADER:
      return { ...state, loader: false };
    case UPDATEMANAGEITEM:
      return { ...state, updateManageItem: action.payload };
      case LOCATIONAPICALL:
      return { ...state, locationApiCall: action.payload };
      case NOTIFICATIONAPI:
        return { ...state, notificationApi: !state.notificationApi};
    default:
      return { ...state };
  }
};

export default reducer;