import React, { Component } from "react";
import { Modal } from "antd";

export class AdqModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title:props.title,
      isTextCenter: props.textCenter,
      isModalOpen: props.isopen,
      hideModal: props.isclose,
      containerClass: props.containerClass,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isopen !== state.isModalOpen) {
      return {
        isModalOpen: props.isopen,
        hideModal: props.isclose,
      };
    }
  }

  render() {
    const { isModalOpen, isTextCenter,containerClass } = this.state;
    return (
      <div style={{ display: "", justifyContent: "left", marginTop: "-10px",}}>
        <Modal
          title={this.state.title}
          open={isModalOpen}
          width={
            this.state.containerClass.fixedwidth
              ? this.state.containerClass.fixedwidth
              : 700
          }
          footer={null}
        >
          <div style={{ display: containerClass.displayStyle?'': "flex", justifyContent:isTextCenter?'':"center", height:this.state.containerClass.height?this.state.containerClass.height:'',  marginTop:this.state.containerClass.marginTop?this.state.containerClass.marginTop:'',
                marginLeft : this.state.containerClass.marginLeft?this.state.containerClass.marginLeft:''}}>
            {this.props.content()}
          </div>
        </Modal>
      </div>
    );
  }
}

export default AdqModel;
