// loader
export const SHOWLOADER = "SHOWLOADER";
export const HIDELOADER = "HIDELOADER";

// loader
export const SAVEBREADCRUMB = "SAVEBREADCRUMB"; 

//ManageInventory reloder
export const UPDATEMANAGEITEM = "UPDATEMANAGEITEM"

//locationApicall
export const LOCATIONAPICALL = "LOCATIONAPICALL"

// Notfication call
export const  NOTIFICATIONAPI = "NOTIFICATIONAPI"
//ORGLOGOAPI Call
export const  ORGLOGOAPI_REQUEST = "ORGLOGOAPI_REQUEST"
export const  ORGLOGOAPI_SUCCESS = "ORGLOGOAPI_SUCCESS"
//ORGLOGOSaveAPI Call
export const  ORGLOGOSAVEAPI_REQUEST = "ORGLOGOSAVEAPI_REQUEST"
export const ORGLOGOSAVEAPI_SUCCESS = "ORGLOGOSAVEAPI_SUCCESS"

//ORGBANKAPI Call
export const  ORGBANKAPI_REQUEST = "ORGBANKAPI_REQUEST"
export const  ORGBANKAPI_SUCCESS = "ORGBANKAPI_SUCCESS"
//ORGBANKGETAPI Call
export const  ORGBANKGETAPI_REQUEST = "ORGBANKGETAPI_REQUEST"
export const  ORGBANKGETAPI_SUCCESS = "ORGBANKGETAPI_SUCCESS"

// ORG- GENERAL CONFIG CALL

export const ORG_GENCONFIGAPI_REQUEST = "ORG_GENCONFIGAPI_REQUEST"
export const SAVE_GENCONFIGAPI_REQUEST = "SAVE_GENCONFIGAPI_REQUEST"
export const GENCONFIGAPI_SUCCESS = "GENCONFIGAPI_SUCCESS"

// for terms and conditions Api calls

export const TERMSCONDISLIST_SUCCESS = "TERMSCONDISLIST_SUCCESS"
export const TERMSCONDISLIST_SAVE = "TERMSCONDISLIST_SAVE"
export const TERMSCONDISLIST_REQUEST = "TERMSCONDISLIST_REQUEST"
export const TERMSCOND_DATAID_SUCCESS = "TERMSCOND_DATAID_SUCCESS"

// for Ledger Trans  Api calls

export const LEDG_TRANS_LIST_SUCCESS = "LEDG_TRANS_LIST_SUCCESS"
export const LEDG_TRANS_LIST_SAVE = "LEDG_TRANS_LIST_SAVE"
export const LEDG_TRANS_LIST_REQUEST = "LEDG_TRANS_LIST_REQUEST"
export const LEDG_TRANS_DATAID_SUCCESS = "LEDG_TRANS_DATAID_SUCCESS"

export const BALANCE_SHEET_ARRAY = 'BALANCE_SHEET_ARRAY'
export const REMOVE_LAST_ITEM = 'REMOVE_LAST_ITEM'
export const CLEAR_ARRAY = 'CLEAR_ARRAY'



