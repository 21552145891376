import React from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";
// import { BrowserRouter } from 'react-router-dom';
import PagenotFound from "./app/PagenotFound";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.variable.min.css';
import "@ant-design/flowchart/dist/index.css";


import { Provider } from 'react-redux';
import { configureStore } from './_redux/store';

if (window.self === window.top) {
  ReactDOM.render(
    // <BrowserRouter>
      <Provider store={configureStore()}>
        <App />
      </Provider>,
    // </BrowserRouter>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render( <PagenotFound />, document.getElementById('root'));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
reportWebVitals();
