import React, { useEffect,useRef, useState } from 'react'
import './App.scss'
import history from './history'
import { Router, Route } from 'react-router-dom'
import Authentication from './Auth';
import LayoutPage from './app/LayoutPage';
import ErrorBoundary from './ErrorBoundary';
import { login } from './configOidc';
import { ConfigProvider,Button } from 'antd';
import PagenotFound from "./app/PagenotFound";
import { PDFDocument } from 'pdf-lib';
import IdleTimer from 'react-idle-timer'
import { logout } from './configOidc';
import AdqModel from './app/commonControls/AdqModel/AdqModel'

function App() {
  const [isLogin, setisLogin] = useState(false);
  const [state, setState] = useState(false)
  const [ visable, setVisable ] = useState(false)

  const IdleTimerRef = useRef(null);
  const modelSttyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    fixedwidth: "340px",
    padding: "0px",
}
  useEffect(() => {
 
    const script1 = document.createElement("script");
    script1.src = "configOidc.js";
    script1.async = true;
    document.body.appendChild(script1);
    const script2 = document.createElement("script");
    script2.src = "oidc-client.js";
    script2.async = true;
    document.body.appendChild(script2);
    const timer = setTimeout(() => {
      var id_token = sessionStorage.getItem('oidc.user:');
      var id = JSON.parse(id_token);
      if (id !== null && isLogin === false) {
        setisLogin(true);
      }
      else if (isLogin === false && id === null) {
        sessionStorage.removeItem('oidc.user:');
        login(); setisLogin(false);
      }
    }, 1000);
    return () => {
      document.body.removeChild(script2);
      document.body.removeChild(script1);
      clearTimeout(timer)
    }
   
  },

  
  []);
  function changeColorTheme(color) {
    ConfigProvider.config({
      theme: {
        primaryColor: "#5673e5", //color,
      }
    });
  }

  const openalert = (e) => {
    setState(true)
    setVisable(true)
  }
  const logoutUser = (e)=>{
    logout()
  }
  window.addEventListener('beforeunload', function (event) {
    event.stopImmediatePropagation();
  });
  
  return (
    <div> 
           {
        state &&  <AdqModel containerClass={modelSttyle} isopen={visable}
        content={()=>(
            <div>
                <p>Your session has expired, please login again</p>
                <div className="vertical-center" style={{ paddingLeft: '83px'}}>
                <Button   onClick={logoutUser}> Okay </Button>
                </div>
                </div>
               

        )}
        />
       }
     {/* <IdleTimer ref={IdleTimerRef} timeout={1000 * 60 * 60 } onIdle={openalert} ></IdleTimer> */}
    <ConfigProvider>
       <Router history={history}>
        <Route exact path="/" > <Authentication /> </Route>
        <Route path="/authentication"> <Authentication /></Route>
        <Route path="/404"> <PagenotFound /></Route>
        {isLogin && (
          <Route path="/layout" >
            <ErrorBoundary>
              <LayoutPage changeThemeColor={changeColorTheme} />
            </ErrorBoundary>
          </Route>
        )
        }
      </Router>
    </ConfigProvider>
    </div>
  );
}

export default App;
