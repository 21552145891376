import axios from "axios";
import { post } from "request";

export const GetWorkFlows = async (orgCode) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/GetWorkFlows?OrgCode=${orgCode}`);
        return response;
    } catch (error) {
        return error.response
    }
}

export const GetWorkFlowDefinitionById = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/GetWorkFlowDefinitionById?id=${id}`);
        return response;
    } catch (error) {
        return error.response
    }
}

export const GetActions = async (Obj) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/GetActions?actionType=${Obj.actionType}`);
        // const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/GetActions`);
        return response;
    } catch (error) {
        return error.response
    }
}

export const SaveWorkflow = async (obj) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/SaveWorkflow`, obj);
        return response;
    } catch (error) {
        return error.response
    }
}

export const UpdateWorkflow = async (obj) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/UpdateWorkflow`, obj);
        return response;
    } catch (error) {
        return error.response
    }
}


export const workFlowStart =  async(Obj)=>{
    try{      
      const response = await axios.post(`${process.env.REACT_APP_BASE_OMS_QAM}/api/v1/workflow/start?stage=ALL&documentType=${Obj.docType}`, Obj);
      return response;
    }
    catch(error){
      return error.response
    }
  }

  export const workFlowaction =  async(Obj)=>{
    try{      
      const response = await axios.post(`${process.env.REACT_APP_BASE_OMS_QAM}/api/v1/workflow/action?documentNumber=${Obj.docType}&action=${Obj.action}`);
      return response;
    }
    catch(error){
      return error.response
    }
  }
    
  export const subOrderSplitTrack = async(Obj)=>{

    try{
      const response = await axios.post(`${process.env.REACT_APP_BASE_OMS_QAM}/api/v1/workflow/sub-order`, Obj);
      return response;
    }
    catch(error){
      return error.response;
    }
  }

  export const OmsPerformAction = async(Obj)=>{
    try{
      const response = await axios.post(`${process.env.REACT_APP_BASE_OMS_QAM}/api/v1/workflow/action?documentNumber=${Obj.docNumber}&action=${Obj.action}`)
      return response;
    }
    catch(error){
      return error.response;
    }
  }


  export const OmsMinimalPublish = async(Obj)=>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_BASE_OMS_QAM}/api/v1/workflow/minimal?version=${Obj.workID}&documentType=${Obj.documentType}`);
        return response;
    }
    catch(error){
        return error.response;
    }
  }


  export const IsInventoryAllocated = async(Obj)=>{
    try{
        const response =  await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/IsInventoryAllocated?OrgCode=${Obj.OrgCode}&TransactionType=${Obj.TransType}&TransactionNo=${Obj.transNo}&ItemSku=${Obj.ItemSku}`);
        return response;
    }
    catch(error){
        return error.response;
    }
  }

  export const ReleaseInventory = async(Obj)=>{
    try{
        const response = await axios.post(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/ReleaseInventory`, Obj);
        return response;
    }
    catch(error){
        return error.response;
    }
  }

  export const flowNextActions = async(Obj)=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_BASE_OMS_QAM}/api/v1/workflow/next-actions?documentNumber=${Obj.documentNO}`);
      return response;
    }
    catch(error){
      return error.response;
    }
  }

  export const LogisticSubmit = (Obj)=>{
    try{
      const response = axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/LogisticSubmit`, Obj);
      return response;
    }
    catch(error){
      return error.response;
    }
  }

  /// for Related to Notification Service 

  export const saveOMsComments = async(Obj)=>{
    try{
      const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/SaveCommentsForTransaction`, Obj);
      return response;
    }
    catch(error){
      return error.response;
    }
  }

  export const getTransComments = async(Obj)=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/GetCommentsForTransaction?OrderId=${Obj.Id}&TransactionId=${Obj.transactionId}&OrgCode=${Obj.OrgCode}`);
      return response;
    }
    catch(error)
    {
      return error.response;
    }
  }

  export const getUserAccessRole = async(Obj)=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/UserAPI/GetRolesbyOrgId?OrgCode=${Obj.OrgCode}&Typename=${Obj.Typename}`);
      return response;
    }
    catch(error)
    {
      return error.response;
    }
  }

  export const handleServiceNotify = async(Obj)=>{
    try{
      const response = {value: true};
      // const response = await post(`${process.env.REACT_APP_OTHER_MASTER_SERVICE}/api/OMS/Savenotification`, Obj`);
    }
    catch(error){
      return error.response;
    }
  }

  export const getNotifyService = async(Obj)=>{
    try{
      // const response = true;
      const response = await axios.get(`${process.env.REACT_APP_OTHER_MASTER_SERVICE}/api/Notification/GetNotifications?OrgCode=${Obj.OrgCode}&UserName=${Obj.UserName}`);
      return response;
    }
    catch(error)
    {
      return error.response;
    }
  }

  export const SaveNotificationFromRoleId = async(OBJ)=>{
    try{
      const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/GetUsersFromRoleId`,OBJ);
      return response;
    }
    catch(error){
      return error.response;
    }
  }

  export const saveWorkFlowforOrder = async(OBJ)=>{
    try{
      const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/SaveOrUpdateWorkflowStatus`,OBJ);
      return response;
    }
    catch(error){
      return error.response;
    }
  }


  export const getOrderWorkflow = async(Obj)=>{
    try{
      const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/GetWorkflowStatus?TransactionNumber=${Obj.TransactionNumber}&TransactionId=${Obj.TransactionId}&OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}`);
      return response;
    }
    catch(error)
    {
      return error.response;
    }
  }
