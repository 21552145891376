export const dashBoardData =
  [
    {
      "title": "Sale Orders",
      "total": 4,
      "dashboardViewList": [
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-02-16T15:50:29.337",
          "transactionDate": "2023-02-16T00:00:00",
          "transactionNumber": "SO/0000012023"
        },
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-02-22T18:05:18.617",
          "transactionDate": "2023-02-22T00:00:00",
          "transactionNumber": "SO/0000012023"
        },
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-02-23T12:42:34.15",
          "transactionDate": "2023-02-23T00:00:00",
          "transactionNumber": "SO/0000022023"
        },
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-03-09T11:34:30.7",
          "transactionDate": "2023-03-09T00:00:00",
          "transactionNumber": "SO/0000032023"
        }
      ],

    },
    {
      "title": "Purcchase Orders",
      "total": 2,
      "dashboardViewList": [
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-02-28T16:06:01.867",
          "transactionDate": "2023-02-28T00:00:00",
          "transactionNumber": "PO/0000012023"
        },
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-03-09T11:44:42.84",
          "transactionDate": "2023-03-09T00:00:00",
          "transactionNumber": "PO/0000022023"
        }
      ]
    },
    {
      "title": "Sale Returns",
      "total": 1,
      "dashboardViewList": [
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-03-09T11:40:12.28",
          "transactionDate": null,
          "transactionNumber": "Sale004"
        }
      ]
    },
    {
      "title": "Purchase Returns",
      "total": 2,
      "dashboardViewList": [
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-03-09T11:49:00.46",
          "transactionDate": null,
          "transactionNumber": "PI/23/003"
        },
        {
          "itemSKU": null,
          "quantity": 0,
          "channelId": null,
          "createdDate": "2023-03-09T12:10:35.153",
          "transactionDate": null,
          "transactionNumber": "PI/23/002"
        }
      ]
    }
  ]

export const oderDashBoard = {
  "orderData": [
    {
      "title": "Sale Orders",
      "total": 4
    },
    {
      "title": "Purcchase Orders",
      "total": 10
    },
    {
      "title": "Sale Returns",
      "total": 41
    }
  ],
  "periodicData": [
    {
      "key": "2023-01-03",
      "value": 54,
      "ordertype": "Sale Orders"
    },
    {
      "key": "2023-01-23",
      "value": 10,
      "ordertype": "Sale Orders"
    },
    {
      "key": "2022-11-03",
      "value": 30,
      "ordertype": "Sale Orders"
    },
    {
      "key": "1853",
      "value": 10,
      "ordertype": "Purcchase Orders"
    }
  ]
}

export const ORDERDashData = [
  {
    "date": "2018/8/1",
    "type": "Sale Order",
    "value": 4623
  },
  {
    "date": "2018/8/1",
    "type": "Sale Return",
    "value": 2208
  },
  {
    "date": "2018/8/1",
    "type": "Purchase Return",
    "value": 228
  },
  {
    "date": "2018/8/1",
    "type": "Purchase Order",
    "value": 182
  },
  {
    "date": "2018/8/2",
    "type": "Sale Order",
    "value": 6145
  },
  {
    "date": "2018/8/2",
    "type": "Sale Return",
    "value": 2016
  },
  {
    "date": "2018/8/2",
    "type": "Purchase Return",
    "value": 28
  },
  {
    "date": "2018/8/2",
    "type": "Purchase Order",
    "value": 257
  },
  {
    "date": "2018/8/3",
    "type": "Sale Order",
    "value": 508
  },
  {
    "date": "2018/8/3",
    "type": "Sale Return",
    "value": 2916
  },
  {
    "date": "2018/8/3",
    "type": "Purchase Return",
    "value": 328
  },
  {
    "date": "2018/8/3",
    "type": "Purchase Order",
    "value": 289
  },
  {
    "date": "2018/8/4",
    "type": "Sale Order",
    "value": 6268
  },
  {
    "date": "2018/8/4",
    "type": "Sale Return",
    "value": 4512
  },
  {
    "date": "2018/8/4",
    "type": "Purchase Return",
    "value": 28
  },
  {
    "date": "2018/8/4",
    "type": "Purchase Order",
    "value": 428
  },
  {
    "date": "2018/8/5",
    "type": "Sale Order",
    "value": 6411
  },
  {
    "date": "2018/8/5",
    "type": "Sale Return",
    "value": 8281
  },
  {
    "date": "2018/8/5",
    "type": "Purchase Return",
    "value": 2208
  },
  {
    "date": "2018/8/5",
    "type": "Purchase Order",
    "value": 619
  },
  {
    "date": "2018/8/6",
    "type": "Sale Order",
    "value": 1890
  },
  {
    "date": "2018/8/6",
    "type": "Sale Return",
    "value": 2008
  },
  {
    "date": "2018/8/6",
    "type": "Purchase Return",
    "value": 25
  },
  {
    "date": "2018/8/6",
    "type": "Purchase Order",
    "value": 87
  },
  {
    "date": "2018/8/7",
    "type": "Sale Order",
    "value": 4251
  },
  {
    "date": "2018/8/7",
    "type": "Sale Return",
    "value": 1963
  },
  {
    "date": "2018/8/7",
    "type": "Purchase Return",
    "value": 98
  },
  {
    "date": "2018/8/7",
    "type": "Purchase Order",
    "value": 706
  },
  {
    "date": "2018/8/8",
    "type": "Sale Order",
    "value": 2978
  },
  {
    "date": "2018/8/8",
    "type": "Sale Return",
    "value": 2367
  },
  {
    "date": "2018/8/8",
    "type": "Purchase Return",
    "value": 2028
  },
  {
    "date": "2018/8/8",
    "type": "Purchase Order",
    "value": 387
  },
  {
    "date": "2018/8/9",
    "type": "Sale Order",
    "value": 3880
  },
  {
    "date": "2018/8/9",
    "type": "Sale Return",
    "value": 2956
  },
  {
    "date": "2018/8/9",
    "type": "Purchase Return",
    "value": 280
  },
  {
    "date": "2018/8/9",
    "type": "Purchase Order",
    "value": 488
  },
  {
    "date": "2018/8/10",
    "type": "Sale Order",
    "value": 3606
  },
  {
    "date": "2018/8/10",
    "type": "Sale Return",
    "value": 678
  },
  {
    "date": "2018/8/10",
    "type": "Purchase Return",
    "value": 898
  },
  {
    "date": "2018/8/10",
    "type": "Purchase Order",
    "value": 507
  },
  {
    "date": "2018/8/11",
    "type": "Sale Order",
    "value": 4311
  },
  {
    "date": "2018/8/11",
    "type": "Sale Return",
    "value": 3188
  },
  {
    "date": "2018/8/11",
    "type": "Purchase Return",
    "value": 1228
  },
  {
    "date": "2018/8/11",
    "type": "Purchase Order",
    "value": 548
  },
  {
    "date": "2018/8/12",
    "type": "Sale Order",
    "value": 4116
  },
  {
    "date": "2018/8/12",
    "type": "Sale Return",
    "value": 3491
  },
  {
    "date": "2018/8/12",
    "type": "Purchase Return",
    "value": 2228
  },
  {
    "date": "2018/8/12",
    "type": "Purchase Order",
    "value": 456
  },
  {
    "date": "2018/8/13",
    "type": "Sale Order",
    "value": 6419
  },
  {
    "date": "2018/8/13",
    "type": "Sale Return",
    "value": 2852
  },
  {
    "date": "2018/8/13",
    "type": "Purchase Return",
    "value": 988
  },
  {
    "date": "2018/8/13",
    "type": "Purchase Order",
    "value": 689
  },
  {
    "date": "2018/8/14",
    "type": "Sale Order",
    "value": 1643
  },
  {
    "date": "2018/8/14",
    "type": "Sale Return",
    "value": 4788
  },
  {
    "date": "2018/8/14",
    "type": "Purchase Return",
    "value": 2928
  },
  {
    "date": "2018/8/14",
    "type": "Purchase Order",
    "value": 280
  },
  {
    "date": "2018/8/15",
    "type": "Sale Order",
    "value": 445
  },
  {
    "date": "2018/8/15",
    "type": "Sale Return",
    "value": 4319
  },
  {
    "date": "2018/8/15",
    "type": "Purchase Return",
    "value": 2858
  },
  {
    "date": "2018/8/15",
    "type": "Purchase Order",
    "value": 176
  }
]


export const DashOrderResponse = {
  data: {
    "message": "Data fetched successfully",
    "success": true,
    "details": [
      {
        "title": "Sale Orders ",
        "total": 15,
        "dashboardViewList": [
          {
            "createdDate": null,
            "transactionDate": "2022-05-23T00:00:00",
            "transactionCount": 3,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-06-09T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-07-18T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-07-25T00:00:00",
            "transactionCount": 3,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-07-01T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-07-26T00:00:00",
            "transactionCount": 2,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-07-27T00:00:00",
            "transactionCount": 3,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-10T00:00:00",
            "transactionCount": 7,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-18T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-24T00:00:00",
            "transactionCount": 6,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-25T00:00:00",
            "transactionCount": 4,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-10-03T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-10-06T00:00:00",
            "transactionCount": 5,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-10-11T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-10-13T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          }
        ]
      },
      {
        "title": "Purchase Orders ",
        "total": 11,
        "dashboardViewList": [
          {
            "createdDate": null,
            "transactionDate": "2022-07-15T00:00:00",
            "transactionCount": 5,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-07-25T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-06-01T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-02T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-10T00:00:00",
            "transactionCount": 2,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-12T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-18T00:00:00",
            "transactionCount": 5,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-23T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-08-24T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-09-30T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": null,
            "transactionDate": "2022-11-02T00:00:00",
            "transactionCount": 1,
            "transactionType": null
          }
        ]
      },
      {
        "title": "Sale Returns",
        "total": 21,
        "dashboardViewList": [
          {
            "createdDate": "2022-02-25T11:25:21.677",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-02-28T16:38:31.903",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-02T14:40:31.513",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-02T14:41:23.06",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-02T15:23:54.753",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-02T15:24:59.197",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-24T14:38:02.243",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-24T15:11:38.8",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-24T16:47:14.56",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-25T13:04:00.42",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-30T15:09:33.423",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-05-12T18:00:42.743",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-05-23T11:45:56.927",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-12T11:47:12.197",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-12T15:22:50.413",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-22T16:44:29.64",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-08-09T10:06:31.55",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-08-18T18:21:58.507",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-08-18T18:25:50.297",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-08-26T11:40:36.453",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-12-01T13:27:54.417",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          }
        ]
      },
      {
        "title": "Purchase Returns",
        "total": 10,
        "dashboardViewList": [
          {
            "createdDate": "2022-02-25T18:04:03.25",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-03T11:13:47.357",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-03-21T13:32:44.58",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-04-11T16:33:26",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-04-12T10:06:25.627",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-05-23T11:43:47.637",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-20T15:55:37.157",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-20T15:57:44.687",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-20T15:58:37.297",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          },
          {
            "createdDate": "2022-07-21T11:52:31.34",
            "transactionDate": null,
            "transactionCount": 1,
            "transactionType": null
          }
        ]
      }
    ]
  }
}


export const OrderFetchData = {
  data: {
    "message": "Data fetched successfully",
    "success": true,
    "orderData": [
      {
        "title": "PurchaseOrder",
        "titleName": "Purchase Orders",
        "total": 0
      },
      {
        "title": "Purchase",
        "titleName": "Purchase Bills",
        "total": 0
      },
      {
        "title": "Purchasereturn",
        "titleName": "Purchase Returns",
        "total": 0
      },
      {
        "title": "SaleOrder",
        "titleName": "Sale Orders",
        "total": 0
      },
      {
        "title": "Sale",
        "titleName": "Sale Invoices",
        "total": 0
      },
      {
        "title": "Salereturn",
        "titleName": "Sale Returns",
        "total": 0
      },
    ],
    "periodicData": []
  }
}


export const orderNamesOBJ = [
  {
    key: "Sale", title: "Sale Invoices", total: 0
  },
  {
    key: "Purchase", title: "Purchase Bills", total: 0
  },
  {
    key: "SaleOrder", title: "Sale Orders", total: 0
  },
  {
    key: "PurchaseOrder", title: "Purchase Orders", total: 0
  },
  {
    key: "Salereturn", title: "Sale Returns", total: 0
  },
  {
    key: "Purchasereturn", title: "Purchase Returns", total: 0
  },
]