import axios from "axios";

export const GetPartyListForTrans = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/PartyMaster/GetPartyListForTransactions?OrgCode=${obj.orgCode}&PartyType=${obj.partyType}&lStart=${obj.startValue}&lEnd=${'5000'}&isOverSeas=${obj.isOverSeas}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getInvoiceTransactionList = async (invoicetype) => {
  const orgCode = localStorage.getItem('OrgCode');
  const subOrgCode = localStorage.getItem('LocCode');
  const type = invoicetype;
  const start = 0;
  const end = 1000;
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransationsList?OrgCode=${orgCode}&SubOrgCode=${subOrgCode}&Type=${type}&start=${start}&end=${'10000'}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const getInvoiceTransactionList1 = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransationsList?OrgCode=${obj.orgCode}&SubOrgCode=${obj.subOrgCode}&Type=${obj.type}&start=${obj.start}&end=${obj.end}&pageNo=${obj.currentPage}&pagesize=${obj.pageSize} &Search=${obj.searchVal}`);
    return response;
  } catch (error) {
    return error.response
  }
}
export const getInvoicebranchtransfer = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransationsList?OrgCode=${obj.orgCode}&SubOrgCode=${obj.subOrgCode}&Type=${obj.type}&start=${obj.start}&end=${obj.end}&pageNo=${obj.currentPage}&pagesize=${obj.pageSize}`);
    return response;
  } catch (error) {
    return error.response
  }
}
export const deleteInvoiceTransactionList = async (transactionNo, id, orgCode, subOrgCode) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/DeleteTransaction?OrgCode=${orgCode}&SubOrgCode=${subOrgCode}&TransactionNo=${transactionNo}&TransactionId=${id}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const getLocationBranchDetails = async (PartyId, OrgCode) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/PartyMaster/GetLocationBranchDetailsByPartyId?PartyId=${PartyId}&OrgCode=${OrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getTaxCalculation = async (taxObj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTaxCalculation`, taxObj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getTransactionDetails = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/AddTransactionDetails`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getTransactionItemDetails = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/AddtransactionItemDetails`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getAddInvoicePartyMappingDetails = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/AddInvoicePartyMappingDetails`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getAddInvoiceSummaryDetails = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/AddInvoiceSummaryDetails`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updateorderstatus = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/UpdateOrderStatus?OrderId=${obj.OrderId}&TransactionId=${obj.TransactionId}&InvoiceId=${obj.InvoiceId}&Type=${obj.Type}&OrgCode=${obj.OrgCode}&SubOrgCode=${obj.SubOrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updatequotationstatus = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/UpdateQuotationOnInvoiceGeneration?QuoteId=${obj.QuoteId}&InvoiceId=${obj.InvoiceId}&OrgCode=${obj.OrgCode}&SubOrgCode=${obj.SubOrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getTransactionDetailForInvoice = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransactionDetailForInvoice?TransactionNumber=${Obj.TransactionNumber}&TransactionId=${Obj.TransactionId}&OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getTransactionDetailForJournal = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransactionDetails?InvoiceNo=${Obj.TransactionNo}&TransactionType=${Obj.TransactionType}&OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getItemDetails = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SUPPLY_BASE_URL}/api/Item/GetItemList?OrgCode=${Obj.OrgCode}&fromdate=${Obj.fromdate}&todate=${Obj.todate}&start=${Obj.start}&count=${'5000'}`);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const deleteTransactionItem = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/DeleteTransaction?OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&TransactionNo=${Obj.TransactionNo}&TransactionId=${Obj.TransactionId}`);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const generateINR = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GenerateIRNNumber`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const cancelIRNNumber = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/CancelIRNNumber`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const CancelTransaction = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/CancelTransaction?OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&TransactionIds=${Obj.TransactionIds}&IsCancel=${Obj.IsCancel}&TransactionType=${Obj.TransactionType}`);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const GetItemListForTransactionWithPagination = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SUPPLY_BASE_URL}/api/Item/GetItemListForTransactionWithPagination?OrgCode=${Obj.OrgCode}&LStart=${Obj.LStart}&LEnd=${'5000'}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const GetItemListForTransactionsWithPagination = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SUPPLY_BASE_URL}/api/Item/GetItemListForTransactionsWithPagination?OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&TransactionType=${Obj.TransType}&LStart=${Obj.LStart}&LEnd=${'5000'}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const CudInventory = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/CUDInventory`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const GetInventoryQtyForTransactions = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/GetInventoryQtyForTransactions?ItemSku=${Obj.item}&OrgCode=${Obj.org}&OrgBranchCode=${Obj.subOrg}&BaseUOM=${Obj.BaseUOM}&SelectedUOM=${Obj.SelectedUOM}&BatchNo=${Obj.BatchNo}&LotNo=${Obj.LotNo}&SerialNo=${Obj.SerialNo}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const GetInvConvertedQty = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SUPPLY_BASE_URL}/api/Item/GetInvConvertedQty?OrgCode=${Obj.org}&ItemSku=${Obj.item}&TargetUOMName=${Obj.Tname}&TargetUOMId=${Obj.Tid}&suborgcode=${Obj.subOrg}&GroupId=${Obj.Gid}&InvQty=${Obj.qty}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const GetAccountsListForTransaction = async (OrgCode, TransactionType) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetAccountsListForTransactions?OrgCode=${OrgCode}&TransactionType=${TransactionType}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const CancelOrDeleteInventory = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/CancelOrDeleteTransactionInventory?Ids=${Obj.Ids}&OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&Cancel=${Obj.Cancel}&Delete=${Obj.Delete}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const CancelOrDeleteInvoice = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/CancelInvoiceListScreen?TransactionNos=${Obj.TransactionNos}&OrgCode=${Obj.OrgCode}&OrgBranchcode=${Obj.OrgBranchcode}&IsCancel=${Obj.IsCancel}&IsDelete=${Obj.IsDelete}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const deleteMultipleTransaction = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/DeleteMultipleTransaction?OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&TransactionIds=${Obj.TransactionIds}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const CancelPosTransaction = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/CancelPosTransaction?OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&TransactionNo=${Obj.TransactionNo}&TransactionId=${Obj.TransactionId}`);
    return response;
  } catch (error) {
    return error.response;
  }
}



export const SavePostings = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/AddAccountingEffects`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getOrgDetails = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}Organisation/GetOrgDetails?OrgCode=${obj.orgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}


export const getSubOrgList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgAPI/GetSubOrgList?OrgCode=${obj.orgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const GetLocationListByUserId = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/GetLocationListByUserId?UserName=${obj.UserName}&OrgCode=${obj.OrgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const getOrgprofile = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgAPI/GetOrgList?OrgCode=${obj.orgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}


export const sendInvoiceEmail = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/SendInvoiceInMail`, obj);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const newlocationAdd = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgAPI/AddLocation`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const updatelocation = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgAPI/EditLocation`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const getserialnumbers = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/GetItemBatchSerial?ItemSKu=${obj.ItemSKu}&OrgCode=${obj.OrgCode}&OrgBranchCode=${obj.OrgBranchCode}&Type=${obj.SerialType}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const AddnewUser = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/AddNewUser`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const Addnewgroup = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/AddGroups`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateUserdata = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/UpdateUser`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getUsers = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/GetUserList?OrgCode=${obj}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const getuserprofile = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/GetUserDetails?Id=${obj.Id}&OrgCode=${obj.OrgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const getgroupdetails = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/GetGroupDetails?Id=${obj.Id}&OrgCode=${obj.OrgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const camparaPassword = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/CompareExistingPassword`, Obj);
    return response;
  } catch (error) {
    return error.response;
  }
}

// TDS && ADJ DropDown Values SERVICE

export const GetGLAccountsList = async (code) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetGLAccountsList?OrgCode=${code}`);
    return response;
  } catch (error) {
    return error.response
  }
}



export const GetAccountingEffectsForInvoice = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetTDSAccountingEffectsForInvoice?TransactionNo=${Obj.TransactionNumber}&&TransactionType=${Obj.TransactionType}&OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}


export const Transactiondeleteposting = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/DeletePostings?TransactionNo=${Obj.TransactionNo}&&TransactionType=${Obj.TransactionType}&OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}`);
    return response;
  }
  catch (error) {
    return error.response
  }
}
export const deleteUser = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/UserAPI/DeleteUser`, obj);
    return response;
  }
  catch (error) {
    return error.response
  }
}

export const GetCreditsListAganistParty = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/OtherTransactions/GetCreditsListAganistParty?OrgCode=${obj.OrgCode}&PartyCode=${obj.PartyCode}&TransactionType=${obj.TransactionType}`)
    return response;
  }
  catch (error) {
    return error.response
  }
}

export const SavePartyCredits = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OtherTransactions/SavePartyCreditsAllocation`, obj)
    return response
  }
  catch (error) {
    return error.response
  }
}
export const VerifyOrderFulfiled = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/VerifyOrderFulfiled?OrgCode=${obj.OrgCode}&SubOrgCode=${obj.SubOrgCode}&Id=${obj.Id}&Type=${obj.Type}`)
    return response;
  }
  catch (error) {
    return error.response
  }
}

export const Addaddationaldetails = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/AddAddtionalFieldsContentDetails`, obj)
    return response
  }
  catch (error) {
    return error.response
  }
}


export const holdInventory = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/HoldInventory`, Obj);
    return response;
  }
  catch (error) {
    return error.response
  }
}


export const SUPWorkflowStatus = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/OMS/SaveOrUpdateWorkflowStatus`, Obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const OmsGeneratetrans = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/TaxCalculationForOMS`, Obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const PhysicalLocationInventory = async (code) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/TransactionMapping/PhysicalLocationInventory?OrgCode=${code}`,);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const UpdateLoginData = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/AddUpdateEInvoiceCredentials`, Obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const getLoginData = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetEInvoiceClientCredentails?OrgCode=${obj.OrgCode}&SubOrgCode=${obj.SubOrgCode}&CredentialsType=${obj.CredentialsType}`);
    return response;
  } catch (error) {
    return error.response;
  }
}


export const getEInvoiceList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetEInvoiceList?OrgCode=${obj.OrgCode}&SubOrgCode=${obj.SubOrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const GetEInvoiceByteCode = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetEInvoiceByteCode?OrgCode=${Obj.OrgCode}&IrnNumber=${Obj.IrnNumber}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const GetEWayBillByteCode = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetEWayBillByteCode?OrgCode=${Obj.OrgCode}&EWayBillNo=${Obj.EWayBillNo}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const generateewaybill = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GenerateEWayBill`, Obj);
    return response;
  } catch (error) {
    return error.response
  }
}
export const GetTransactiondateForLedger = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransactiondateForLedger?OrgCode=${Obj.OrgCode}&DeactivationDate=${Obj.Deactivationdate}&ledgerId=${Obj.id}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const GetOrgBankDataList = async (OrgCode) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetOrgBankDataList?OrgCode=${OrgCode}`);
    return response;
  } catch (error) {
    return error.response;
  }
}
export const AllcoateCreditsByTransactionId = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/LedgerTransaction/AllcoateCreditsByTransactionId`, Obj);
    return response;
  } catch (error) {
    return error.response
  }
}