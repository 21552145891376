import React, { useEffect, useState } from 'react'
import AdqModel from '../app/commonControls/AdqModel/AdqModel'
import { Button, Select, Steps, message } from 'antd'
import { FiCheckCircle, FiAlertTriangle } from "react-icons/fi";
import { getModuleList, addModulesForOrganisation } from '../_services/subscriptionService';

const { Option } = Select;

const modelSttyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    width: "70%",
    padding: "0px",
    fixedwidth: 500,
}
const statusDescription = [
    {
        key: 1,
        text: 'Your subscription request has been sent to uBooks finance team. Team will get back to you shortly.',
        subText: 'Please find the status of your subscription request below.'
    },
    {
        key: 2,
        text: 'Your subscription payment is received by Finance team and is sent for Admin approval now.',
        subText: 'Application can be accessed once admin approves your request.'
    }
]
const itemData = [
    {
        title: 'Initiated',
    },
    {
        title: 'Finance',
    },
    {
        title: 'Admin',
    },
    {
        title: 'Completed',
    },

]

function SubscriptionReq(props) {
    const [moduleScr, setModuleScr] = useState([])
    const [period, setPeriod] = useState(1)
    const [stepStatus, setStepStatus] = useState()
    const extendlist = [1, 3, 6, 12]
    useEffect(() => {
        loadApi()
        updateSteps()

    }, [])
    useEffect(() => {
        updateSteps()
    }, [props.requestStatus])

    const updateSteps = () => {
        switch (props.requestStatus) {
            case 0:
            case 1:
                return setStepStatus(0)
            case 2:
            case 3:
                return setStepStatus(1)
            case 4:
            case 5:
                return setStepStatus(2)
            default:
                return setStepStatus(3)
        }
    }
    const loadApi = async () => {
        await getModuleList().then(res => {
            let result = res.data.details
            setModuleScr(result)

        })
    }
    const handleChange = (e, text) => {
        if (text === "selectChage") {
            setPeriod(e)
        }
        else {
            let obj = {
                RequestStatus: 1,
                RequestedDuration: period,
                OrgCode: localStorage.getItem('OrgCode'),
                ModuleIds: moduleScr.map((item => item.id))
            }
            updateModule(obj)

        }
    }
    const updateModule = async (payload) => {
        props.loader(true)
        await addModulesForOrganisation(payload).then((res) => {
            if(res.status === 200){
                if(res.data.success){
                    message.success(res.data.message)
                    setStepStatus(1)
                }
                else {
                    message.error(res.data.message)
                }
            }
            else {
                message.error('Please try after some time')
            }
            props.loader(false)
        })
    }
    const InitStatus = () => (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <span>If you want contiune services please extend your subcription</span>
            </div>
            {
                moduleScr.map((item) => (
                    <div style={{ position: 'relative', left: '35%', marginTop: '10px' }}>
                        <FiCheckCircle color='blue' size={30} /><span style={{ fontSize: '14px', marginLeft: '10px' }}>{item.moduleName}</span>
                    </div>
                ))
            }
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                <span><b>Select subscription period</b></span>
                <Select defaultValue={extendlist[0]} value={period} onChange={(e) => handleChange(e, 'selectChage')}>
                    {
                        extendlist.map((li, ind) => (
                            <Option key={ind} value={li} selectOptionValue={li}>   {li === 1 ? `${li} Month` :`${li} Months`}</Option>
                        ))
                    }
                </Select>
                <Button type="primary" style={{ marginRight: '5px', marginLeft: '5px', marginTop: '10px' }} onClick={(e) => handleChange(e, 'click')} >Subscribe</Button>
            </div>
        </div>

    )
    if(props.subscriptionExpWarning){
        return (
            <AdqModel containerClass={modelSttyle} isopen={props.isOpen} textCenter={true}
            content={() =><>
             {/* <i className="fas fa-times recallClose" onClick={() =>props.modalChange(false)} /> */}
             <div style={{ width: '100%', backgroundColor: '#fff' }}>
             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: '', width: '100%' }}>
                    <FiAlertTriangle color='red' size={30} />
                    <span style={{ fontSize: '16px',marginLeft:'10px' }}>{props.subscriptionExpWarning}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', width: '100%' }}>
                <Button type="primary" onClick={() =>props.modalChange(false)} >ok</Button>
                </div>
                </div>
            </>
             
            }/>

        )
    }
    return (
        <AdqModel containerClass={modelSttyle} isopen={props.isOpen} textCenter={true}
            content={() => <div style={{ width: '100%', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <FiAlertTriangle color='red' size={50} />
                    <span style={{ fontSize: '18px' }}>Your pack is expired.</span>
                </div>
                {stepStatus === 0 && <InitStatus />}
                {stepStatus > 0 && stepStatus < 3 && <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <span style={{ textAlign: 'center', marginTop: '20px' }}>{statusDescription[stepStatus - 1].text}</span>
                    <span style={{ textAlign: 'center', marginTop: '20px' }}>{statusDescription[stepStatus - 1].subText}</span>

                    <div style={{ marginTop: '20px' }}>
                        <Steps
                            progressDot
                            current={stepStatus}
                            items={itemData}
                        />
                    </div>
                </div>
                }
            </div>}

        />
    )
}

export default SubscriptionReq