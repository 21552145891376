import { SHOWLOADER, HIDELOADER, UPDATEMANAGEITEM, LOCATIONAPICALL } from "../actions";

export const showLoader = (request) => ({
  payload: request,
  type: SHOWLOADER,
});
export const hideLoader = (response) => ({
  payload: response,
  type: HIDELOADER,
});

export const updateManageItem = (request) => {
  return {
    payload: request,
    type: UPDATEMANAGEITEM,
  }
};

export const locationApiCall = (request) => {
  return {
    payload: request,
    type: LOCATIONAPICALL,
  }
};
export const notificationApi= (request) => {
  return {
    payload: request,
    type: LOCATIONAPICALL,
  }
};