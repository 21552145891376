import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { ORGBANKAPI_REQUEST,} from "../actions";
import { orgBankApi_success,  } from "./action";
import { getOrgBankList } from "../../_services/AccountService";

// for bank data def data
export function* watchOrgBank() {
  yield takeLatest(ORGBANKAPI_REQUEST, getORGBankData);
}

function* getORGBankData({ payload }) {
  try {
    const response = yield call(fetchBankDataFromAPI);
    yield put(orgBankApi_success(response));    
  } 
  catch (error) {
    // yield put(fetchDataFailure(error.message));
  }
}

// API Function
const fetchBankDataFromAPI = async()=> {
  let Obj = { orgCode: localStorage.getItem('OrgCode') }
  
  let bankDataRDx = {
    defBankName: {
      bankName: "N.A",
      id: 0,
    },
    orgBankList : [],
  }
  await getOrgBankList(Obj).then((response) => {           
    if (response.status === 200) {            
      let dataResp = response.data.result
      let data = dataResp.details;
      let defBank = data.filter((x) => x.isDefaultBank === true)
      let OriginalData = response.data.result.details;
      OriginalData = OriginalData.filter((x) => x.isDefaultBank !== true)
      
      if (defBank && defBank.length > 0) {
        let datBank = defBank[0]
        OriginalData.unshift(datBank)
        //let defBankObj = datBank.bankName + "," + datBank.accountNumber + "," + datBank.ifscCode    
        bankDataRDx.defBankName = datBank
        bankDataRDx.defBankExists = true
      }        
      bankDataRDx.orgBankList = OriginalData
    }
  })     
  // Replace with your API endpoint
  return bankDataRDx 
}

export default function* rootSaga() {
    yield all([
      fork(watchOrgBank),
      // fork(watchSaveOrgLOGO),
    ]);
  }
  

 