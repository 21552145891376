import { GENCONFIGAPI_SUCCESS, LEDG_TRANS_DATAID_SUCCESS, LEDG_TRANS_LIST_REQUEST, 
LEDG_TRANS_LIST_SUCCESS, ORGLOGOAPI_REQUEST, ORGLOGOAPI_SUCCESS, ORGLOGOSAVEAPI_REQUEST,
ORG_GENCONFIGAPI_REQUEST, SAVE_GENCONFIGAPI_REQUEST, TERMSCONDISLIST_REQUEST, 
TERMSCONDISLIST_SUCCESS, TERMSCOND_DATAID_SUCCESS } from "../actions";

export const orgLogoApi_success= (request) => {
  return {
    payload: request,
    type: ORGLOGOAPI_SUCCESS,
  }
};

export const orgLogoApi_request= (request) => {
  return {
    payload: request,
    type: ORGLOGOAPI_REQUEST,
  }
};

export const OrgLogoSaveApi_request= (request) => {
  return {
    payload: request,
    type: ORGLOGOSAVEAPI_REQUEST,
  }
};

// for general Config use below 

export const saveConfigApi_success= (request) => {
  return {
    payload: request,
    type: GENCONFIGAPI_SUCCESS,
  }
};

export const generalConfigApi_request= (request) => {
  return {
    payload: request,
    type: ORG_GENCONFIGAPI_REQUEST,
  }
};

export const generalconfigSaveApi_request= (request) => {
  return {
    payload: request,
    type: SAVE_GENCONFIGAPI_REQUEST,
  }
};

export const getListofTerms_request= (request) => {
  return {
    payload: request,
    type: TERMSCONDISLIST_REQUEST,
  }
};

export const termsCond_Request_Success = (request)=>{
  return {
    payload : request,
    type : TERMSCONDISLIST_SUCCESS
  }
}

export const getTerms_ById = (request)=>{
  return {
    payload : request,
    type : TERMSCOND_DATAID_SUCCESS
  }
}

// for ledger trans effect

export const getLedgTransList_request= (request) => {
  return {
    payload: request,
    type: LEDG_TRANS_LIST_REQUEST,
  }
};

export const LedgTrans_Request_Success = (request)=>{
  return {
    payload : request,
    type : LEDG_TRANS_LIST_SUCCESS
  }
}

export const getLedgTrans_ById = (request)=>{
  return {
    payload : request,
    type : LEDG_TRANS_DATAID_SUCCESS
  }
}
