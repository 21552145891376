import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { Card, DatePicker, message, Empty } from 'antd';
import './RegistrationDashboard.css'
import { GetRegDashbroadData } from '../../../_services/InventoryService'
import moment from 'moment';
import { DateStringFormat, convToAPIFormat, splitDate } from '../../../_helper/dateUtil';
import { dashBoardData, ORDERDashData,OrderFetchData, orderNamesOBJ } from '../../../_localDB/RegistrationdashboardDb';
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../../_redux/loader/action.js';
import {isBrowser, isMobile } from 'react-device-detect';
import { FiMoreVertical } from "react-icons/fi";

const CtrlData = {
    OrderFetchData: JSON.stringify(OrderFetchData),
}

function RegistrationDashboard(props) {

    const { RangePicker } = DatePicker;
    const [dddata, setDashboardData] = useState([]);
    const [data, setOrderData] = useState([]);
    const [widgetdata, setWidgetdata] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('');
    const [showBtns, setShowBtns] = useState(false);
    const [linedata, setlinedata] = useState([]);
    const [activeState, activesetStatus] = useState('Month');
    const [dateFormat, setdateFormat] = useState(null);
    const [headerFilterOpen,setHeaderFilterOpen] = useState(false)
    const [dateForm, setdateForm] = useState(localStorage.getItem('dateFormat'));

    const config = {
        data,
        xField: 'itemSKU',
        yField: 'quantity',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: false,
                autoRotate: true,
            },
        },
        meta: {
            type: {
                alias: 'category',
            },
            sales: {
                alias: 'sales',
            },
        },
    };

    const lineconfig = {
        data,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        color: ({ type }) => {
            return type === 'Sale Orders' ? '#F4664A'
                : type === 'Purchase Orders' ? '#30BF78'
                    : type === "Sale Returns" ? '#e1c4c4'
                        : '#FAAD14';
        },
        lineStyle: ({ type }) => {
            if (type === 'Sale Returns') {
                return {
                    lineDash: [4, 4],
                    opacity: 1,
                };
            }
        }

    };

    useEffect(()=>{
        var formatDate;
        if(dateForm === "1"){
            formatDate = 'MM-DD-YYYY';
        }
        else{
            formatDate = 'DD-MM-YYYY';
        } 
        setdateFormat(formatDate);
    },[])

    useEffect(() => {
    }, [data])

    useEffect(() => {
        if(dateFormat !== null){
            getDates("Month");
        }
    }, [dateFormat])

    useEffect(() => {
        if (startDate && endDate && dateFormat !== null) {
            InventoryDashboradData();
        }
    }, [startDate, endDate])

    const handleOrderDash = () => {
        setOrderData(ORDERDashData)
    }

    const InventoryDashboradData = async () => {
        let obj = {
            OrgCode: localStorage.getItem('OrgCode'),
            OrgBranchCode: localStorage.getItem('LocCode'),
            StartDate: convToAPIFormat(startDate),
            EndDate: convToAPIFormat(endDate, "End")              
        }
        props.showLoaderAsync(true);
        await GetRegDashbroadData(obj).then(response => {
            let OrderFetchData = JSON.parse(CtrlData.OrderFetchData)
            let StaticData = OrderFetchData.data.orderData
            props.hideLoaderAsync(false);
            if (response.data) {
                let DataObj = response.data.orderData
                let periodicDataObj = response.data.periodicData.length > 0 ? response.data.periodicData
                    : OrderFetchData.data.periodicData

                let saleQty = 0
                let saleObj = StaticData.findIndex((y)=> y.title === "Sale")
                DataObj.forEach((z) => {
                    let ObjInd = StaticData.findIndex((y)=> y.title === z.title)
                    if(ObjInd !== -1){
                        StaticData[ObjInd].total = z.total
                    }                    
                    if(z.title === "POS" || z.title === "CashSales" ){
                        saleQty += z.total
                    }
                })     
                    // merge sale/pos/cashsales total commented for now    
                // if(saleObj !== -1){
                //     StaticData[saleObj].total += saleQty
                // }



                periodicDataObj.forEach((z) => {
                    let findInd = orderNamesOBJ.findIndex((x) => x.key === z.orderType)
                    if (findInd !== -1) {
                        z.titleName = orderNamesOBJ[findInd].title
                    }

                    z.date = splitDate(z.orderDay)
                    z.value = z.orderCount
                    z.type = z.titleName
                })
                setOrderData(sortByDate(periodicDataObj))
                setDashboardData(dashBoardData[0].dashboardViewList)
            } else {

                message.error(response.data.message);
            }
            setWidgetdata(StaticData);
        })
    }


    // sort Data by date...
    const sortByDate = (arr) => {
        arr.sort(function (a, b) {
            return Number(new Date(a.date)) - Number(new Date(b.date));
        });

        return arr;
    }

    const loadDashboad = (item) => {
        let headers = item.dashboardViewList.map(x => x.itemSKU);
        if (item.title === "Orders") {
            setShowBtns(true)
        } else {
            setShowBtns(false)
        }
        let uniqueValues = headers.filter((value, index, self) => {
            return self.indexOf(value) === index;
        })
        let finalObject = [];
        uniqueValues.forEach((value, i) => {
            let dataObj = item.dashboardViewList.filter(x => x.itemSKU === value);
            if (dataObj.length === 1) {
                finalObject.push(dataObj[0]);
            }
            else if (dataObj.length > 1) {
                let TotalSum = dataObj.reduce((previousValue, currentValue) => {
                    return parseInt(previousValue) + parseInt(currentValue.quantity)
                }, 0);
                let NewData = JSON.parse((JSON.stringify(dataObj[0])));
                NewData.quantity = TotalSum;
                finalObject.push(NewData);
            }
        })
        setDashboardData(finalObject);
    }

    const onPickerHandler = (item, value, type) => {
        if (value[0] !== '' || value[1] !== '') {     
            let d1 = changeDateFormat(value[0])       
            let d2 = changeDateFormat(value[1])       
            // if (d2.getTime() <= d1.getTime()) {
            if ((Date.parse(d1) <= Date.parse(d2))) {
                setStartDate(value[0]);        
                setEndDate(value[1]);
                setStatus("Custom Date");
            }
            else {
                alert("End Date should be greater than Start Date")
            }
        }
    }

    const changeDateFormat = (value)=>{
        let spArr = value.split("-");
        if (dateForm !== "1") {
            value = [spArr[1], spArr[0], spArr[2]].join("-");
        } 
        return new Date(value);
    }      

    const getDates = (type) => {
        activesetStatus(type);
        if (type === "Day") {
            setStartDate(DateStringFormat(new Date()));
            setEndDate(DateStringFormat(new Date()));
            setStatus("Today");
        } else if (type === "Week") {
            let getMonth = moment().subtract(0, "week").startOf("week").format(dateFormat);
            let EndMonth = moment().subtract(0, "week").endOf("week").format(dateFormat);
            setStartDate(getMonth);
            setEndDate(EndMonth);
            setStatus("This Week");
        } else if (type === "Month") {
            let getMonth = moment().subtract(0, "month").startOf("month").format(dateFormat);
            let EndMonth = moment().subtract(0, "month").endOf("month").format(dateFormat);
            setStartDate(getMonth);
            setEndDate(EndMonth);
            setStatus("This Month");
            
        } else if (type === "Year") {
            let FinStart = new Date(new Date().getFullYear(), 3, 1);
            let FinEnd = new Date(new Date().getFullYear()+1, 2, 31);
            setStartDate(DateStringFormat(FinStart));
            setEndDate(DateStringFormat(FinEnd));            
            setStatus("This Year");
        }
    }

    const onSellingItemsHandler = (type) => {
        if (type === "fast") {

        } else if (type === "slow") {

        }
    }
    const HeaderFilters=()=>{
        return(
            <div className="inventroryActionIcons" style={isMobile?{display:'grid',gridTemplateColumns:'auto',gap:'5px',width:'50%',background:'#fff',marginTop:'0px',zIndex:1000}:{}}>
            <button onClick={() => getDates("Day")} className={activeState === 'Day' ? 'invActive' : ''}>Day</button>
                <button onClick={() => getDates("Week")} className={activeState === 'Week' ? 'invActive' : ''}>Week</button>
                <button onClick={() => getDates("Month")} className={activeState === 'Month' ? 'invActive' : ''}>Month</button>
                <button onClick={() => getDates("Year")} className={activeState === 'Year' ? 'invActive' : ''}>Year</button>
                <RangePicker 
                    format={dateFormat} 
                    allowClear={true}
                    // onCalendarChange={(e, i, a) => onPickerHandler(e, i, a)} 
                    onChange={(e, i, a) => onPickerHandler(e, i, a)}                     
                    value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]} 
                />
                </div>
        )
    }

    return (
        <section>
            {
                isMobile ? (
                    <div className="inventroryActionIcons">
                        <FiMoreVertical onClick={()=>setHeaderFilterOpen(!headerFilterOpen)} />
                    </div>
                ) : (<HeaderFilters />)
            }
             {headerFilterOpen && <HeaderFilters/>}
            <div className="row" style={{ margin: "0 -15px" }}>
                {widgetdata && widgetdata.map(item => {
                    return (
                        <div className="col-md-2 cardView" onClick={() => loadDashboad(item)}>
                            <Card style={{ cursor: "pointer" }}>
                                <h5 className='regh4'>{item.titleName}</h5>
                                <div className="card-widget">
                                    <img width={27}
                                        src={item.titleName === "Purchase Orders" ? "/images/PurchaseOrder.png"
                                            : item.titleName === "Sale Orders" ? "/images/order.png"
                                                : item.titleName === "Sale Returns" ? "/images/SaleReturn.png"
                                                : item.titleName === "Purchase Returns" ? "/images/PurchaseReturn.png"
                                                : item.titleName === "Sale Invoices" ? "/images/PurchaseReturn.png"
                                                : item.titleName === "Purchase Bills" ? "/images/PurchaseReturn.png"
                                                        : ''} alt="" />
                                    <div>
                                        <h4 className='regh4' >{item.total}</h4>
                                        {status !== '' ?
                                            <span className='regdate' ><i className="fas fa-calendar-alt"></i> {status}</span> : ''}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )
                })}
            </div>

            {/* from here line chart */}


            {data && data.length > 0 ?
                <div className="tableSearchPosition mt-3">
                    <div className="dashboardheader px-2 pt-1">
                        <h3>Orders Overview</h3>
                        <div>
                            {showBtns ?
                                <>  <button onClick={() => onSellingItemsHandler("fast")}>Fast Selling Items</button>
                                    <button onClick={() => onSellingItemsHandler("slow")}>Slow Selling Items</button>
                                </> : ''
                            }
                        </div>
                    </div>
                    <section className="p-2 dashFixed">
                        <Line {...lineconfig} />
                    </section>
                </div>
                :
                <div className="tableSearchPosition mt-3">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-5 py-5"
                        description={
                            <span className="text-center">
                                <b>No Data</b><br />
                            </span>
                        }
                    />
                </div>
            }


        </section>
    )
}
const mapStateToProps = ({ loader_reducer, breadCrumb_reducer }) => {
    return { loader_reducer, breadCrumb_reducer }
}
const mapDispatchToProps = { showLoaderAsync: showLoader, hideLoaderAsync: hideLoader };

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationDashboard);
