/* eslint-disable no-unused-vars */
import { all } from "redux-saga/effects";
import loaderSaga from "./loader/saga";
import breadCrumbSaga from './breadCrumb/saga';
import orgLogoSaga from './OrgLogo/saga';
import orgBankSaga from './OrgBank/saga';
import balanceSaga from './balanceSheetArray/saga'

export default function* rootSaga(getState) {
  yield all([
    loaderSaga(),
    breadCrumbSaga(),
    orgLogoSaga(),
    orgBankSaga(),
    balanceSaga()
  ]);
}
