import React, { Component } from "react";
import { Button, Tooltip } from "antd";

class AdqButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.buttonData,
    };
    this.valuesHandleChange = this.valuesHandleChange.bind(this);
  }

  valuesHandleChange = (index, obj) => {
    if (index === 0) {
      this.props.buttonAction(obj);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.buttonData !== state.buttonData) {
      return {
        data: props.buttonData,
      };
    }
  }

  render() {
    const { data } = this.state;
    return (
      <span>
        {data ? (
          <Tooltip title={data.text}>
            <Button
              type={data.type}
              className={data.className}
              id={data.id + "btn"}
              onClick={(e) => this.valuesHandleChange(0, data)}
              disabled={data.isDisabled}
            >
              {data.icon === true ? <i className="fas fa-plus mr-2"></i> : ""}
              {data.onlyIcon === true ? (
                <i className="fas fa-plus"></i>
              ) : (
                data.text
              )}
            </Button>
          </Tooltip>
        ) : (
          ""
        )}
      </span>
    );
  }
}

export default AdqButton;
