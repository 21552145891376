import axios from "axios";

export const getHelpContent = async(obj) => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/OrgApi/GetHelpContent`)
      return response;      
    }catch (error){
      return error
    }
  }

  export const getBroadcastContent = async(obj) => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/OrgApi/GetBroadcastDetails`)
      return response;      
    }catch (error){
      return error
    }
  }
