import axios from "axios";

export const SaveAccountDetails = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/SaveAccountDetails`, obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const getAccountList = async () => {
  const CompanyCode = localStorage.getItem('OrgCode');
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetAccountList?OrgCode=${CompanyCode}`);
    return response;
  } catch (error) {
    return error.response
  }
}


export const getTESTAccountList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetAccountsData?Orgcode=${obj.id}`);
    return response;
  } catch (error) {
    return error.response
  }
}


export const getAccountDetails = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetAccountDetails?ACCode=${Obj.ACCode}&ACId=${Obj.ACId}&OrgCode=${Obj.OrgCode}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const DeActiveAccount = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/DeActivateAccounts?ACCode=${Obj.ACCode}&ACId=${Obj.ACId}&OrgCode=${Obj.OrgCode}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const GetGLAccountsListData = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetGLAccountsList`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const OpeningBalanceDetails = async (Obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/OpeningBalance/AddOpeningBalanceDetails`, Obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const GetOpeningBalanceDetails = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/OpeningBalance/GetOpeningBalancebyAcCodeName?OrgCode=${Obj.OrgCode}&AccountCode=${Obj.AccountCode}`);
    return response;
  } catch (error) {
    return error.response
  }
}
export const getjournalData = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetJournalTransactionList?OrgCode=${Obj.orgCode}&subOrgCode=${Obj.subOrgCode}&TransactionType=${Obj.TransactionType}&Fromdate=${Obj.Fromdate}&Todate=${Obj.Todate}&Start=0&End=${Obj.End}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const GetAccountTransationsList = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetAccountTransationsList?OrgCode=${Obj.OrgCode}&SubOrgCode=${Obj.SubOrgCode}&Fromdate=${Obj.Fromdate}&Todate=${Obj.Todate}&TransactionType=${Obj.TransactionType}`)
    return response
  }
  catch (error) {
    return error.response
  }
}

export const SaveOrgBankData = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/SaveOrgBankData`, obj);
    return response;
  }
  catch (error) {
    return error.response;
  }
}

export const getOrgBankList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetOrgBankDataList?OrgCode=${obj.orgCode}`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}

export const getOrgBankById = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsMaster/GetOrgBankById?orgCode=${Obj.orgCode}&id=${Obj.id}`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}
export const chartofAccountList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetChartOfAccountList?OrgCode=${obj.orgCode}`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}
export const getLedgerList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetLedgerList?OrgCode=${obj.orgCode}`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}
export const getLedgerIdbase = async (Id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetLedgerDetailsById?Id=${Id}`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}
export const getTdsTcsList = async (obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetTdsTcsMasterData`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}
export const Saveledger = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/SaveLedgerDetails`, obj);
    return response;
  }
  catch (error) {
    return error.response;
  }
}
export const GetAccountsList = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetAccountsList?orgcode=${Obj.orgCode}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const GetReportTypeList = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetReportTypeList?Id=${id}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const AddOrUpdateChartOfAccountDetails = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/AddOrUpdateChartOfAccountDetails`, obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const ChartOfAccountDetailsById = async (Id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/EditChartOfAccountDetails?Id=${Id}`);
    return response;
  }
  catch (error) {
    return error.response;
  }
}

export const GetLatestLedgerId = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetLatestLedgerId`);
    return response;
  } catch (error) {
    return error.response
  }
}
export const GetChartOfAccountByPartyId = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetChartOfAccountByPartyId?ParentId=${Obj.ParentId}&orgCode=${Obj.orgCode}&partyEnum=${Obj.partyEnum}`);
    return response;
  } catch (error) {
    return error.response
  }
}
export const GetTermsAndConditionsListByTransactionId = async (trnTypeId) =>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/GetTermsAndConditionsListByTransactionId?TrnTypeId=${trnTypeId}&OrgCode=${localStorage.getItem('OrgCode')}`);
    return response;
  }catch(error){
    return error.response
  }

}

export const getLedgerEffectList = async(Obj)=>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetLedgerEffectList?OrgCode=${Obj.OrgCode}`);
    return response;
  }
  catch(error){
    return error.response
  }
}

export const saveUpdateLedgerEffect = async(Obj)=>{
  try{
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/saveTransactionLedgerEffect`, Obj);
    return response;
  }
  catch(error){
    return error.response
  }
}

export const CreateCOAMasterDataForOrganisation = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/IsMasterDataForAccounting?OrgCode=${obj.OrgCode}`);
    return response;
  } catch (error) {
    return error.response
  }
}

export const GetCOAStatusToDeactivation = async (Obj) => {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/GetCOAStatusToDeactivation?orgCode=${Obj.orgCode}&deactivationdate=${Obj.deactivationdate}&COAId=${Obj.id}&deactivate=${Obj.deactivate}`);
    return response;      
  }catch (error){
    return error.response;
  }
}

export const getTransAlocByLedgId = async(Obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/LedgerTransaction/GetTransAllocDetailsByLedger?ledgerId=${Obj.ledgerId}&type=${Obj.transType}&transId=${Obj.transId}&OrgCode=${Obj.orgCode}&isParty=${Obj.isParty}`);
    return response;      
  }catch (error){
    return error.response;
  }
}

export const getLedgDetailsByTransId = async(Obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/LedgerTransaction/GetLedgerDetailsByTransactionId?OrgCode=${Obj.orgCode}&SubOrgCode=${Obj.subOrgCode}&TransactionId=${Obj.transactionId}`);
    return response;      
  }catch (error){
    return error.response;
  }
}

export const ledgTranSaveAPI = async(Obj)=>{
  // Obj.PartyName = Obj.PartyName ? Obj.PartyName : null;
  // Obj.partyLedgerId = Obj.partyLedgerId? Obj.partyLedgerId:0;
  // Obj.partyCode = Obj.partyCode? Obj.partyCode:null;
  // Obj.locationCode = Obj.locationCode? Obj.locationCode:null;
  try{
    const response =  await axios.post(`${process.env.REACT_APP_SALE_BASE_URL}/api/LedgerTransaction/SaveLedgerDetailsForTransactions`, Obj);
    return response;      
  }catch (error){
    return error.response;
  }
}

export const UpdatePartyIdForLedger = async(Lid,Pid) => {
  try {
    const response =  await axios.post(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsPosting/UpdatePartyIdForLedger?partyid=${Pid}&ledgerid=${Lid}`);       
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getReportList= async(obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsReports/GetGLReport?FromDate=${obj.fromDate}&ToDate=${obj.toDate}&OrgCode=${obj.orgCode}&LedgerId=${obj.selectedLedgerId}&MinAmt=${obj.minAmount}&MaxAmt=${obj.maxAmount}&isforwardbalance=true`);
    return response;   
  }catch (error){
    return error.response;
  }
}   
export const getcreditors = async(Obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsReports/SundrycredtorsOutstandingReports?OrgCode=${Obj.OrgCode}&TillDate=${Obj.TillDate}`);
    return response;      
  }catch (error){
    return error.response;
  }
}
export const getDebitors = async(Obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsReports/SundryDebtorsOutstandingReports?OrgCode=${Obj.OrgCode}&TillDate=${Obj.TillDate}`);
    return response;      
  }catch (error){
    return error.response;
  }
}
export const GetProfitAndLossAllQuarters = async(obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsReports/GetProfitAndLossAllQuarters?OrgCode=${obj.OrgCode}&FromDate=${obj.fromDate} &ToDate=${obj.toDate}&openingStock=${obj.openingStock}&openingStockInTransit=${obj.openingStockInTransit}&closingStock=${obj.closingStock}&closingStockInTransit=${obj.closingStockInTransit} &purchaseBillsToMake=${obj.purchaseBillsToMake}&salesBillsToMake=${obj.salesBillsToMake}&provisionalExpenses=${obj.provisionalExpenses}`);
    return response;      
  }catch (error){
    return error.response;
  }
}

export const GetOpeningStockValues = async(obj)=> {
  try{
    const response =  await axios.get(`${process.env.REACT_APP_ACCOUNT_MASTER_URL}/api/AccountsReports/GetOpeningStockValues?OrgCode=${obj.OrgCode}&FromDate=${obj.fromDate} &ToDate=${obj.toDate}`);
    return response;      
  }catch (error){
    return error.response;
  }
}

export const GetLedgerPostingTransactionList =async(obj)=>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/LedgerTransaction/GetLedgerPostingTransactionList?OrgCode=${obj.orgCode}&TransactionType=${obj.transactionType}`);
    return response;
  } catch(error){
    return error.response;
  }
}