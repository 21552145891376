import { combineReducers } from "redux";
import loader_reducer from "./loader/reducer";
import breadCrumb_reducer from "./breadCrumb/reducer";
import orglogo_reducer from "./OrgLogo/reducer";
import orgBank_reducer from "./OrgBank/reducer";
import balance_sheet_list from './balanceSheetArray/reducer'

const reducers = combineReducers({
  loader_reducer,
  breadCrumb_reducer,
  orglogo_reducer,
  orgBank_reducer,
  balance_sheet_list,
});

export default reducers;
