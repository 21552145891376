import React, { Component } from 'react'
import InventoryDashboard from '../../Inventory/InventoryDashboard'
import RegistrationDashboard from './RegistrationDashboard';
import { Segmented } from 'antd';

export default class DashBoardTab extends Component {
    constructor(props) {
        super(props);
        this.state = props.layState !== undefined ? props.layState : {} ;
        this.state.isInventory = 'Inventory';
        this.state.selectedKey = "0";
        // this.state = {
        //     selectedKey: "0",
        //     isInventory: 'Inventory',
        // }
    }

    componentDidMount = ()=>{
        if(this.props.layState !== undefined){
            let menulist = this.state.navbarlist
            let invtInd = menulist.findIndex((x)=> x.key === '40')
            let salesInd = menulist.findIndex((x)=> x.key === '28')  // Sales  //65 Sale Order
    
            let dashBoardShow = "Order"
            if(salesInd !== -1){
                dashBoardShow = 'Order'
            }
            else if(invtInd !== -1){
                dashBoardShow = "Inventory"
            }             
            this.setState({ isInventory : dashBoardShow}, ()=>{
                this.onToggleChange(dashBoardShow)
            })
        }
    }

    handleLinkClick = (obj) => {
        this.setState({
            selectedKey: obj
        })
    }

    onToggleChange = (type) => {
        this.setState({ isInventory: type })
    }

    onChangeTabHandler = (tabIndex) => {
        this.setState({
            selectedKey: tabIndex
        })
      }

    render() {
        const { isInventory} = this.state;
        return (
            <div>
                <div className="customTabs" style={{ left: 0, marginTop: "-55px", }}>
                    {/* <Switch style={{
                        background: localStorage.getItem("themeUpdate") ? localStorage.getItem("themeUpdate") : '#F5A623',
                        marginTop: "-2px"
                    }}
                        checkedChildren="Inventory Dashboard" unCheckedChildren="Order Dashboard"
                        onChange={this.onToggleChange} id="Dashboard"
                        checked={this.state.isInventory}
                    /> */}
                    <label htmlFor="" className="pr-4" style={{fontSize: '14px',marginLeft:'-24px'}}>Dashboard:</label>
                    <Segmented 
                        value = {isInventory}
                        options={['Inventory', 'Order']} 
                        onChange={(e) => this.onToggleChange(e)} 
                    />
                </div>
                {this.state.isInventory === "Inventory" ?
                    <InventoryDashboard /> :
                    <RegistrationDashboard />
                }
            </div>
            // <div>
            // <div className="customTabs" style={{ left: 0, paddingTop: "4px", paddingLeft: "120px" }}>
            //     <Tabs className="partymaster-Content-section px-2" activeKey={this.state.selectedKey} onTabClick={this.onChangeTabHandler} >
            //         <TabPane tab="Inventory Dashboard" key="0">
            //             <InventoryDashboard />
            //         </TabPane>
            //         <TabPane tab="Order Dashboard" key="1">
            //             <RegistrationDashboard />
            //         </TabPane>
            //     </Tabs>
            // </div>
            // </div>
        )
    }
}
