import { all, fork, takeLatest } from "redux-saga/effects";
import { SHOWLOADER } from "../actions";
import { showLoader, hideLoader } from "./action";
  
export function* watchIsLoader() {
  yield takeLatest(SHOWLOADER, isLoader);
}

function* isLoader({ payload }) {
  if(payload === true){
    showLoader();
  }
  else {
    hideLoader();
  }
 
}

export default function* rootSaga() {
    yield all([
      fork(watchIsLoader),
    ]);
  }
  

 