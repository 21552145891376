import { BALANCE_SHEET_ARRAY, CLEAR_ARRAY, REMOVE_LAST_ITEM } from "../actions";

const initialState = []
 
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BALANCE_SHEET_ARRAY:
      return [...state, action.payload]
      case REMOVE_LAST_ITEM:
        return state.slice(0,-1)
        case CLEAR_ARRAY:
            return []
    default:
      return state;
  }
};

export default reducer;