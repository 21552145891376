import axios from "axios";

export const SaveGenConfig = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_OTHER_GENERIC_SERVICE}/api/OtherGenericService/SaveGeneralConfigDetails`, obj);
    return response;
  } catch (error) {
    return error.response
  }
}


export const getGenConfig = async (Obj) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_OTHER_GENERIC_SERVICE}/api/OtherGenericService/GetGeneralConfigDetails?OrgCode=${Obj.OrgCode}`);
    return response;

  } catch (error) {
    return error.response;
  }
}

export const getTranExits = async (Obj) => {
  try {
    const reponse = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetTransactionExist?OrgCode=${Obj.OrgCode}`)
    return reponse
  }
  catch (error) {
    return error.reponse
  }
}

export const getOrgLogo = async (Obj) => {
  try {
    const reponse = await axios.get(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgApi/GetLogoDetails?OrgCode=${Obj.OrgCode}`)
    return reponse
  }
  catch (error) {
    return error.reponse
  }
}

export const saveOrgLogo = async (Obj) => {
  try {
    const reponse = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgApi/AddLogoDetails`, Obj)
    return reponse
  }
  catch (error) {
    return error.reponse
  }
}

export const UpdateBusinessType = async (obj) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}Organisation/UpdateBusinessType`, obj);
    return response;
  } catch (error) {
    return error.response
  }
}

export const posttaxillalogout = async () => {
  try {
    const reponse = await axios.post(`${process.env.REACT_APP_TAXILA_REDIRECT}/idm/logout`)
    return reponse
  }
  catch (error) {
    return error.reponse
  }
}