import axios from "axios";


export const getModuleList = async(obj) => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/UserAPI/GetModulesListWithCost`);            
      return response;      
    }catch (error){
      return error
    }
  }

  export const addModulesForOrganisation = async(payload) => {
    try{
     const response = await axios.post(`${process.env.REACT_APP_lOCAL_AUTHORITY}api/OrgAPI/AddModulesForOrganisation`,payload);            
      return response;      
    }catch (error){
      return error
    }
  }