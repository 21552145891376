// import { combineReducers } from "redux";
import {
  ORGBANKAPI_SUCCESS,
} from "../actions";

export const initialBankState = {
  orgBankData: {
    defBankName: {
      bankName : "N.A",
    },
    defBankExists : false,
    orgBankList : []
  }
};

const reducer = (state = initialBankState, action) => {
  switch (action.type) {
    case ORGBANKAPI_SUCCESS:
      return {
        ...state,        
        orgBankData: action.payload && action.payload !== undefined ? action.payload : initialBankState,
      };             
    default:
      return { ...state };
  }
};

export default reducer;