import axios from "axios";

export const getStateList = async () => {    
    try{
      const response = await axios.get(`${process.env.REACT_APP_OTHER_GENERIC_SERVICE}/api/OtherGenericService/GetStateList`);
      return response;
    }catch(error){
      return error.response
    }
  }

  export const getCountryList = async () => {    
    try{
      const response = await axios.get(`${process.env.REACT_APP_OTHER_GENERIC_SERVICE}/api/OtherGenericService/GetCountryList`);
      return response;
    }catch(error){
      return error.response
    }
  }

  export const EnScoreSignUp = async (obj) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/OrgApi/EnScoreSignUp?OrgCode=${obj.OrgCode}`);
      return response;
    } catch (error) {
      return error.response
    }
  }
export const getScreenMenuList = async() => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/UserAPI/GetScreenList`);            
      return response;      
    }catch (error){
      return error
    }
  }
export const GetScreenListByUser = async(obj) => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_PRODUCTION_AUTHORITY}api/UserAPI/GetScreenListByUserId?UserName=${obj.UserName}&OrgCode=${obj.OrgCode}`);            
      return response;      
    }catch (error){
      return error
    }
  }
  