import { SAVEBREADCRUMB } from "../actions";

const initialState = {
  breadCrumbList : null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVEBREADCRUMB:
      return {
        ...state,
        breadCrumbList: action.payload
      };
    default:
      return { ...state };
  }
};

export default reducer;