import axios from "axios";

export const GetInventoryDashboradData = async(obj) => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_COMMONINVENTORY}/api/Dashboard/GetInventoryDashboradData?OrgCode=${obj.OrgCode}&OrgBranchCode=${obj.OrgBranchCode}&StartDate=${obj.StartDate}&EndDate=${obj.EndDate}`);            
      return response;      
    }catch (error){
      return error
    }
  }


  export const GetRegDashbroadData = async(obj) => {
    try{
     const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/Transaction/GetOrdersDashboardData?OrgCode=${obj.OrgCode}&StartDate=${obj.StartDate}&EndDate=${obj.EndDate}`);            
      return response;      
    }catch (error){
      return error
    }
  }