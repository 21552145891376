import React, { Component } from 'react';
import { message } from 'antd';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false,
        success:true
        };
    }
  
      /////Calling error message/////    
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };            
    }
  
    componentDidCatch = async(error, info) => {     
        this.handleMsg(error);                      
    }

///////Alert Error Message///////    
    handleMsg = (error)=>{        
        var errormsg = error.toString()
        if(this.state.success){
            message.error(errormsg)
        }
    }
/////////Error message save as text file (not Used)//////
    download = (filename, error, info) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + error.toString()+ " Error Info:-> "+info);        
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    
    render() {
      if (this.state.hasError) {        
        return <h1>Error....</h1>
      }
      else {
        return this.props.children; 
      }
    }
  }

  export default ErrorBoundary
