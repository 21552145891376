import React, { Component } from "react";
import { Modal } from "antd";

class AdqDialogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogClose: props.dialogBox,
      title: props.dialogContentProps ? props.dialogContentProps.title : "",
      subText: props.dialogContentProps ? props.dialogContentProps.subText : "",
    };
  }

  handleOk = () => {
    this.handleDialogBox("Yes");
  };

  handleCancel = () => {
    this.handleDialogBox("No");
  };

  handleDialogBox = (type) => {
    this.setState({ dialogClose: false });
    this.props.selectedDialogBoxValue(type, false);
  };

  render(props) {
    const { dialogClose, title, subText } = this.state;
    return (
      <Modal
        title={title}
        visible={dialogClose}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <p>{subText}</p>
      </Modal>
    );
  }
}

export default AdqDialogBox;
