import axios from "axios"

export const SaveDocNumberService = async ( inputObj ) => {
  try {
    const response = await axios.post( `${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/SaveDocNumber`, inputObj );
    return response;
  } catch ( error ) {
    return error.response;
  }
}

export const UpdateDocNumberService = async ( inputObj ) => {
    try {
      const response = await axios.post( `${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/UpdateDocNumber`, inputObj );
      return response;
    } catch ( error ) {
      return error.response;
    }
  }

export const getSupplyDetails = async (id, trnsid) => {
    const orgCode = localStorage.getItem('OrgCode')  
    try{
      const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/GetDocNumberById?Id=${id}&TrnTypeId=${trnsid}&OrgCode=${orgCode}`);
      return response;
    }catch(error){
      return error.response
    }
  }

export const GetDocNumList = async () => {
    const orgCode = localStorage.getItem('OrgCode')  
    const subOrg = localStorage.getItem('LocCode')  
    try{
      const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/GetDocNumList?OrgCode=${orgCode}&SubOrgCode=${subOrg}`);
      return response;
    }catch(error){
      return error.response
    }
  }

export const CheckDocNumFormatExistence = async (id, orgCode) =>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/CheckDocNumFormatExistence?Id=${id}&OrgCode=${orgCode}`);
    return response;
  }catch(error){
    return error.response
  }
}

export const DeleteDocnumSeries = async (id, orgCode) =>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/DeleteDocnumSeries?Id=${id}&OrgCode=${orgCode}`);
    return response;
  }catch(error){
    return error.response
  }
}

export const GetTermsAndConditionsList = async (Obj) =>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/GetTermsAndConditionsList?OrgCode=${Obj.orgCode}`);
    return response;
  }catch(error){
    return error.response
  }
}

export const GetTermsAndConditionById = async (id, orgCode) =>{
  try{
    const response = await axios.get(`${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/GetTermsAndConditionById?Id=${id}&OrgCode=${orgCode}`);
    return response;
  }catch(error){
    return error.response
  }
}

export const SaveTermsAndCondition = async ( Obj ) => {
  try {
    const response = await axios.post( `${process.env.REACT_APP_SALE_BASE_URL}/api/SeriesGeneration/SaveTermsAndCondition`, Obj );
    return response;
  } catch ( error ) {
    return error.response;
  }
}

