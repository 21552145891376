// import { combineReducers } from "redux";
import { GENCONFIGAPI_SUCCESS, ORGLOGOAPI_REQUEST, ORGLOGOAPI_SUCCESS,
  ORGLOGOSAVEAPI_REQUEST,
  TERMSCONDISLIST_SUCCESS,
  TERMSCOND_DATAID_SUCCESS, LEDG_TRANS_DATAID_SUCCESS,LEDG_TRANS_LIST_SUCCESS } from "../actions";

const initialState = {
  orgLogoData : '',
  generalConfig : {},
  termsConditionsList : [],
  termsCondIDObj : "",
  ledgTransList : [],
  ledgTransIDObj : "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGLOGOAPI_REQUEST:
      return { ...state, orgLogoData: action.payload};    
    case ORGLOGOAPI_SUCCESS:
        return { ...state, orgLogoData: action.payload};    
    case ORGLOGOSAVEAPI_REQUEST:
      return { ...state,};    
    case GENCONFIGAPI_SUCCESS:
      return { 
        ...state, 
        generalConfig : action.payload,
      };
    case TERMSCONDISLIST_SUCCESS:
      return {
        ...state,
        termsConditionsList : action.payload,
      };
    case TERMSCOND_DATAID_SUCCESS:
      let data = state.termsConditionsList.filter((x)=> x.trnTypeId === action.payload.Id)
      let termsObj  = data && data.length > 0 ? data[0].termsAndConditions : "" 
      
      return {
        ...state,
        termsCondIDObj :  termsObj,
      }
    case LEDG_TRANS_LIST_SUCCESS:
      return {
        ...state,
        ledgTransList : action.payload,
      }
    case LEDG_TRANS_DATAID_SUCCESS:
      let ledgData = state.ledgTransList.filter((x)=> x.ledgerEffect === true
        && x.transactionName === action.payload.Id)
      let ledgTransObj  = ledgData && ledgData.length > 0 ? ledgData[0] : false
      
      return {
        ...state,
        ledgTransIDObj :  ledgTransObj,
      } 
    default:
      return { ...state };
  }
};

export default reducer;