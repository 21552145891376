import React, { Component } from "react";
import { Result, Button } from 'antd';
import { Link } from "react-router-dom";


class PagenotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customeColor: localStorage.getItem("themeUpdate"),
        };
    };
    render() {
        return (
            <Result status="404" subTitle="You don't have Access this Page"
                extra={
                    <Link to="/layout">
                        <Button type="primary" id="BackHome"
                            style={{
                                background: this.state.customeColor ? this.state.customeColor : null,
                                borderColor: this.state.customeColor ? this.state.customeColor : null
                            }} >Back Home</Button>
                    </Link>
                } />
        );
    }
}

export default PagenotFound;