import { logout } from '../configOidc';

export function getUserName() {

}

export const ThemesCells = [
  '#F5A623', '#ca5010', '#986f0b', '#8cbd18', '#0b6a0b', '#038387', '#004e8c', '#104dba'
]

export const _overflowItems = [
  { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
  { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
  { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
];


export const _items = [
  {
    key: 'key1',
    text: '',
    iconProps: { iconName: 'UserFollowed' },
    subMenuProps: {
      items: [
        {
          key: 'userNmae',
          text: localStorage.getItem('UserName'),
          iconProps: { iconName: 'UserFollowed' },
        },
        {
          key: 'logout',
          text: 'Logout',
          iconProps: { iconName: 'SignOut' },
          onClick: () => logout(),
        }
      ],
    },
  },
];

export const itemsWithHeading = {
  text: 'Dashboard',
  key: 'Home'
};

export const navLinkGroups = [
  {
    links: [
      {
        name: 'Organization',
        isExpanded: false,
        key: 'key001',
        icon: 'Org',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Manage Locations',
            key: 'key001a',
            icon: 'Org',
            url: '/layout/Manage Locations',
          },
          {
            name: 'Roles',
            key: 'key001b',
            icon: 'Org',
            url: '/layout/Manage Role',
          },

        ]
      },
      {
        name: 'Purchase Transation',
        isExpanded: false,
        key: 'key002',
        icon: 'ServerProcesses',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Purchase Invoice',
            key: 'key002a',
            icon: 'Money',
            url: '/layout/Purchase Bills',
          },
          {
            name: 'Purhase Invoice Template',
            key: 'key002b',
            icon: 'CreateMailRule',
            url: '/layout/purchasetemplate',
          },
        ],
      },
      {
        name: 'Configuration',
        isExpanded: false,
        key: 'key003',
        icon: 'ServerProcesses',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Aditional Field Config.',
            key: 'key003a',
            icon: 'Font',
            url: '/layout/Additional Field',
          },
          {
            name: 'Attributes',
            key: 'key003b',
            icon: 'Font',
            url: '/layout/Attributes',
          },
          {
            name: 'Category Defination',
            key: 'key003c',
            icon: 'MapLayers',
            url: '/layout/Category',
          },
          {
            name: 'Document Number Generation',
            key: 'key003d',
            icon: 'NumberSequence',
            url: '/layout/Number Series',
          },
        ],
      },
      {
        name: 'Payments',
        isExpanded: false,
        key: 'key004',
        icon: 'Money',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Payment',
            key: 'key004a',
            icon: 'PaymentCard',
            url: '/layout/Payments',
          },
          {
            name: 'Receipt',
            key: 'key004b',
            icon: 'Articles',
            url: '/layout/Sale Receipt',
          }
        ]
      },
      {
        name: 'Other Transactions',
        isExpanded: false,
        key: 'key005',
        icon: 'AddOnlineMeeting',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Opening Balance',
            key: 'key005a',
            icon: 'ReadingMode',
            url: '/layout/Opening Balance',
          },
          {
            name: 'Manage Inventory',
            key: 'key005b',
            icon: 'VisualsStore',
            url: '/layout/Manage Inventory',
          },
          {
            name: 'Inventory Adjustment',
            key: 'key005c',
            icon: 'AddTo',
            url: '/layout/Inventory Adjustment',
          },
        ]
      },
      {
        name: 'Manage Orders',
        isExpanded: false,
        key: 'key006',
        icon: 'ProductList',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Purchase Order',
            key: 'key006a',
            icon: 'AddTo',
            url: '/layout/Purchase Order',
          },
          {
            name: 'Purchase View',
            key: 'key006b',
            icon: 'AddTo',
            url: '/layout/purchasetemplate',
          },
          {
            name: 'Sale Order',
            key: 'key006c',
            icon: 'Articles',
            url: '/layout/Sale Order',
          }
        ]
      },
      {
        name: 'Accounting Reports',
        isExpanded: false,
        key: 'key007',
        icon: 'Money',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Journal Reports',
            key: 'key007a',
            icon: 'Money',
            url: '/layout/Journal Reports',
          },
          {
            name: 'Account Transactions',
            key: 'key007b',
            icon: 'Money',
            url: '/layout/AccountTransactions',
          },
          {
            name: 'Trial Balance',
            key: 'key007c',
            icon: 'Money',
            url: '/layout/TrialBalance',
          },
          {
            name: 'Profit And Loss (Schedule III)',
            key: 'key007d',
            icon: 'Money',
            url: '/layout/profitandloss',
          },
          {
            name: 'Balance Sheet (Schedule III)',
            key: 'key007e',
            icon: 'Money',
            url: '/layout/balancesheet',
          }

        ]
      },
      {
        name: 'Data Management',
        isExpanded: false,
        key: 'key008',
        icon: 'QuestionnaireMirrored',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Data Import/Export',
            key: 'key008a',
            icon: 'CompanyDirectoryMirrored',
            url: '/layout/Data Import Export',
          },
        ]
      },
      {
        name: 'E-Way Bill',
        isExpanded: false,
        key: 'key009',
        icon: 'QuestionnaireMirrored',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'E-Way Bill',
            key: 'key009a',
            icon: 'CompanyDirectoryMirrored',
            url: '/layout/E-Way Bill',
          },
          {
            name: 'NIC Configuration',
            key: 'key009b',
            icon: 'DietPlanNotebook',
            url: '/layout/NIC Configuration',
          },
        ]
      },
      {
        name: 'Grouping Controls',
        isExpanded: false,
        key: 'key010',
        icon: 'ButtonControl',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'adq-address-grouping',
            key: 'key010a',
            icon: 'CustomizeToolbar',
            url: '/layout/grouping-ctrl/adq-addresscontrol',
          }
        ]
      },
    ]
  }
];

export const options = [
  { key: 'defaultTheme', text: 'defaultTheme', displayValue: 'Default' },
  { key: 'lightTheme', text: 'lightTheme', displayValue: 'Light' },
  { key: 'darkTheme', text: 'darkTheme', displayValue: 'Dark' }
];

export const FontsList =
{
  typectrl: "drp",
  ctrltype: "dropdown",
  placeHolder: "Select",
  key: 'SegoeUI',
  disabled: false,
  isRequired: true,
  errormsg: false,
  name: 'registration',
  label: "Reasons For Issuing",
  Option: [
    { key: 'SegoeUI', text: 'SegoeUI' },
    { key: 'Roboto', text: 'Roboto' },
    { key: 'OpenSans', text: 'OpenSans' },
    { key: 'Montserrat', text: 'Montserrat' },
    { key: 'Raleway', text: 'Raleway' },
    { key: 'Merriweather', text: 'Merriweather' },
    { key: 'Quicksand', text: 'Quicksand' },
  ],
};

export const NavLinksNew = [
  {
    key: 'key001',
    name: 'Organization',
    icon: 'fas fa-crown',
    submenu: [
      {
        name: 'Manage Locations',
        key: 'key001a',
        icon: 'fab fa-algolia',
        url: '/layout/Manage Locations',
      },
      {
        name: 'Roles',
        key: 'key001b',
        icon: 'fas fa-users-cog',
        url: '/layout/Manage Role',
      },
    ]
  }, {
    key: 'key02',
    name: 'Masters',
    icon: 'fas fa-bezier-curve',
    submenu: [
      {
        name: 'Item Master',
        key: 'key02b',
        icon: 'fas fa-luggage-cart',
        url: '/layout/Supply Master',
      },
      {
        name: 'Party Master',
        key: 'key02a',
        icon: 'fas fa-user',
        url: '/layout/Party Master',
      },
      {
        name: 'Unit of Measure',
        key: 'key02d',
        icon: 'fas fa-balance-scale',
        url: '/layout/Unit Conversions',
      },
    ]
  },
  {
    key: 'key03',
    name: 'Sales',
    icon: 'fas fa-users',
    submenu: [
      {
        name: 'Invoice',
        key: 'key03a',
        icon: 'fas fa-file-invoice',
        url: '/layout/Sale Invoice',
      },
      {
        name: 'Returns',
        key: 'key03b',
        icon: 'fas fa-exchange-alt',
        url: '/layout/Sale Return',
      },
      {
        name: 'Credit Notes',
        key: 'key03c',
        icon: 'fas fa-credit-card',
        url: '/layout/Sale Credit Note',
      },
      {
        name: 'Debit Notes',
        key: 'key03d',
        icon: 'far fa-credit-card',
        url: '/layout/Sale Debit Note',
      }, {
        name: 'Orders',
        key: 'key006c',
        icon: 'fas fa-plus-circle',
        url: '/layout/Sale Order',
      }, {
        name: 'Receipts',
        key: 'key004b',
        icon: 'fas fa-receipt',
        url: '/layout/Sale Receipt',
      }
    ]
  },

  {
    key: 'key002',
    name: 'Purchases',
    icon: 'fas fa-hand-holding-usd',
    submenu: [
      {
        name: 'Bills',
        key: 'key002a',
        icon: 'fas fa-file-invoice-dollar',
        url: '/layout/Purchase Bills',
      },
      {
        name: 'Returns',
        key: 'key012b',
        icon: 'fas fa-exchange-alt',
        url: '/layout/Purchase Return',
      },
      {
        name: 'Credit Notes',
        key: 'key012c',
        icon: 'fas fa-credit-card',
        url: '/layout/Purchase Credit Note',
      },
      {
        name: 'Debit Notes',
        key: 'key013d',
        icon: 'far fa-credit-card',
        url: '/layout/Purchase Debit Note',
      },
      {
        name: 'Orders',
        key: 'key006a',
        icon: 'fas fa-plus-circle',
        url: '/layout/Purchase Order',
      }, {
        name: 'Payments',
        key: 'key004a',
        icon: 'fas fa-money-bill-wave',
        url: '/layout/Payments',
      },
    ],
  }, {
    key: 'key004',
    name: 'Accounts',
    icon: 'fas fa-columns',
    submenu: [
      {
        name: 'General Ledger',
        key: 'key02c',
        icon: 'far fa-user-circle',
        url: '/layout/General Ledger',
      }, {
        name: 'Opening Balance',
        key: 'key005a',
        icon: 'fas fa-book-open',
        url: '/layout/Opening Balance',
      },
    ]

  }, {
    key: 'key005',
    name: 'Inventory',
    icon: 'fas fa-atlas',
    submenu: [
      {
        name: 'Manage Inventory',
        key: 'key005b',
        icon: 'fas fa-warehouse',
        url: '/layout/Manage Inventory',
      },
      {
        name: 'Inventory Adjustment',
        key: 'key005c',
        icon: 'fas fa-adjust',
        url: '/layout/Inventory Adjustment',
      },
    ]

  }, {
    key: 'key009',
    name: 'E-Way Bill',
    icon: 'fas fa-receipt',
    submenu: [
      {
        name: 'E-Way Bill',
        key: 'key009a',
        icon: 'fas fa-table',
        url: '/layout/E-Way Bill',
      },
      {
        name: 'NIC Configuration',
        key: 'key009b',
        icon: 'fas fa-info-circle',
        url: '/layout/NIC Configuration',
      },
    ]
  }, {
    key: 'key008',
    name: 'Data Management',
    icon: 'fas fa-database',
    submenu: [
      {
        name: 'Data Import/Export',
        key: 'key008a',
        icon: 'fas fa-table',
        url: '/layout/Data Import Export',
      },
    ]
  },
  {
    key: 'key003',
    name: 'Configuration',
    icon: 'fas fa-atom',
    submenu: [
      {
        name: 'General',
        key: 'key003e',
        icon: 'far fa-check-square',
        url: '/layout/Configuration',
      },
      {
        name: 'Additional Field',
        key: 'key003a',
        icon: 'fas fa-folder-plus',
        url: '/layout/Additional Field',
      },
      {
        name: 'Categories',
        key: 'key003c',
        icon: 'fas fa-layer-group',
        url: '/layout/Category',
      }, {
        name: 'Attributes',
        key: 'key003b',
        icon: 'fas fa-bullseye',
        url: '/layout/Attributes',
      },
      {
        name: 'Number Series',
        key: 'key003d',
        icon: 'fas fa-list-ol',
        url: '/layout/Number Series',
      },
    ],
  },
  {
    key: 'key007',
    name: 'Accounting Reports',
    icon: 'fas fa-file-invoice-dollar',
    submenu: [
      {
        name: 'Journal Reports',
        key: 'key007a',
        icon: 'fas fa-file-invoice-dollar',
        url: '/layout/Journal Reports',
      },
      {
        name: 'Account Transactions',
        key: 'key007b',
        icon: 'fas fa-file-invoice-dollar',
        url: '/layout/AccountTransactions',
      },
      {
        name: 'Trial Balance',
        key: 'key007c',
        icon: 'fas fa-file-invoice-dollar',
        url: '/layout/TrialBalance',
      },
      {
        name: 'Profit & Loss (Schedule III)',
        key: 'key007d',
        icon: 'fas fa-file-invoice-dollar',
        url: '/layout/profitandloss',
      },
      {
        name: 'Balance Sheet (Schedule III)',
        key: 'key007e',
        icon: 'fas fa-file-invoice-dollar',
        url: '/layout/balancesheet',
      }

    ]
  },
  {
    key: 'key11',
    name: 'Catelogue',
    icon: 'fas fa-receipt',
    submenu: [
      {
        name: 'Channel Details',
        key: 'key11a',
        icon: 'fas fa-book',
        url: '/layout/ChannelDetails',
      },
      {
        name: 'Catelogue Details',
        key: 'key11b',
        icon: 'fas fa-book',
        url: '/layout/CatelogueDetails',
      },
      {
        name: 'Catelogue Allocation',
        key: 'key11c',
        icon: 'fas fa-book',
        url: '/layout/CatelogueAllocation',
      },
      {
        name: 'Catelogue Item Mapping',
        key: 'key11d',
        icon: 'fas fa-book',
        url: '/layout/CatelogueItemMapping',
      },
    ]
  },
  {
    key: 'key01',
    name: 'Reports',
    icon: 'fas fa-clipboard-list',
    submenu: [
      {
        name: 'Reports',
        key: 'key01a',
        icon: 'fas fa-book',
        url: '/layout/Reports',
      },
    ]
  },
]

export const notificaitons = [
  {
    id: 1,
    sourceType: "User",
    message: "A new user has been added",
    targetType: "Broadcast",
    orgCode: "ApC0658K",
    actionType: 1,
    link: "47968, WO/221223/18932, AV4D402G, AV4D402GL, Accepted,",
    userName: "",
    createDate: "2022-08-25T10:22:16.26",
    expiryDate: "2022-08-25T10:52:17.017"
  },
  // {
  //   id: 2,
  //   sourceType: "User",
  //   message: "Round off in transactions has been enabled",
  //   targetType: "Broadcast",
  //   orgCode: "ApC0658K",
  //   actionType: 1,
  //   link: "47968, WO/221223/18932, AV4D402G, AV4D402GL, Accepted,",
  //   userName: "",
  //   createDate: "2022-08-25T10:22:16.26",
  //   expiryDate: "2022-08-25T10:52:17.017"
  // },
  {
    id: 3,
    sourceType: "User",
    message: "Allow Negative Quantity has been enabled",
    targetType: "Broadcast",
    orgCode: "ApC0658K",
    userName: "",
    createDate: "2022-08-25T10:22:16.26",
    expiryDate: "2022-08-25T10:52:17.017"
  },
  {
    id: 4,
    sourceType: "User",
    message: "Track Inventory has been disabled",
    targetType: "Broadcast",
    orgCode: "ApC0658K",
    userName: "",
    createDate: "2022-08-25T10:22:16.26",
    expiryDate: "2022-08-25T10:52:17.017"
  },
  {
    id: 5,
    sourceType: "User",
    message: "Tds Adjustments has been disabled",
    targetType: "Broadcast",
    orgCode: "ApC0658K",
    userName: "",
    createDate: "2022-08-25T10:22:16.26",
    expiryDate: "2022-08-25T10:52:17.017"
  },
  {
    id: 6,
    sourceType: "User",
    message: "A new quotation has been added",
    targetType: "Broadcast",
    orgCode: "ApC0658K",
    userName: "",
    createDate: "2022-08-25T10:22:16.26",
    expiryDate: "2022-08-25T10:52:17.017"
  },
  {
    id: 7,
    sourceType: "User",
    message: "Other Adjustments has been added",
    targetType: "Broadcast",
    orgCode: "ApC0658K",
    userName: "",
    createDate: "2022-08-25T10:22:16.26",
    expiryDate: "2022-08-25T10:52:17.017"
  }
]

export const staticMenu = [
  {
    "key": "31",
    "name": "Organization",
    "icon": "fas fa-sitemap",
    "submenu": [
      {
        "key": "8",
        "name": "Users",
        "icon": "fas fa-users",
        "url": "/layout/Users List",
        "submenu": [

        ]
      },
      {
        "key": "16",
        "name": "Roles",
        "icon": "fas fa-users-cog",
        "url": "/layout/Manage Role",
        "submenu": [

        ]
      },
      {
        "key": "17",
        "name": "Configuration",
        "icon": "fas fa-user-cog",
        "url": "/layout/General Configuration",
        "submenu": [
          {
            "key": "48",
            "name": "General",
            "icon": "far fa-check-square",
            "url": ""
          }
        ]
      },
      {
        "key": "33",
        "name": "Masters",
        "icon": "fas fa-user",
        "url": null,
        "submenu": [
          {
            "key": "5",
            "name": "Party Master",
            "icon": "fas fa-user",
            "url": "/layout/Party Master"
          },
          {
            "key": "27",
            "name": "Unit of Measure",
            "icon": "fas fa-balance-scale",
            "url": "/layout/Unit Conversions"
          },
          {
            "key": "59",
            "name": "Catalogue",
            "icon": "fas fa-book",
            "url": "/layout/CatelogueDetails"
          }
        ]
      },
      {
        "key": "34",
        "name": "Locations",
        "icon": "fab fa-algolia",
        "url": "/layout/Manage Locations",
        "submenu": [

        ]
      },
      {
        "key": "69",
        "name": "Groups",
        "icon": "fas fa-users",
        "url": "/layout/GroupsList",
        "submenu": [

        ]
      },
      {
        "key": "74",
        "name": "Sales Channel",
        "icon": "fas fa-sitemap",
        "url": "/layout/ChannelDetails",
        "submenu": [

        ]
      },
      {
        "key": "93",
        "name": "Subscriptions",
        "icon": "fas fa-key",
        "url": "/layout/subscriptions",
        "submenu": [

        ]
      },
      {
        "key": "94",
        "name": "Integration Management",
        "icon": "fas fa-cogs",
        "url": "/layout/integrationmanagement",
        "submenu": [

        ]
      },
      {
        "key": "95",
        "name": "Subscriptions",
        "icon": "fas fa-key",
        "url": "/layout/subscriptions",
        "submenu": [

        ]
      },
      {
        "key": "96",
        "name": "Integration Management",
        "icon": "fas fa-cogs",
        "url": "/layout/integrationmanagement",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "40",
    "name": "Inventory Management",
    "icon": "fas fa-warehouse",
    "submenu": [
      {
        "key": "7",
        "name": "Item Master",
        "icon": "fas fa-luggage-cart",
        "url": "/layout/Supply Master",
        "submenu": [

        ]
      },
      {
        "key": "41",
        "name": "Inventory Master",
        "icon": "fas fa-warehouse",
        "url": "/layout/Manage Inventory",
        "submenu": [

        ]
      },
      {
        "key": "42",
        "name": "Inventory Adjustment",
        "icon": "fas fa-adjust",
        "url": "/layout/Inventory Adjustment",
        "submenu": [

        ]
      },
      {
        "key": "50",
        "name": "Categories",
        "icon": "fas fa-layer-group",
        "url": "/layout/Category",
        "submenu": [

        ]
      },
      {
        "key": "51",
        "name": "Attributes",
        "icon": "fas fa-bullseye",
        "url": "/layout/Attributes",
        "submenu": [

        ]
      },
      {
        "key": "76",
        "name": "Branch Transfer",
        "icon": "fas fa-exchange-alt",
        "url": "/layout/Branch Transfer List",
        "submenu": [

        ]
      },
      {
        "key": "92",
        "name": "Integrations",
        "icon": "fas fa-chart-network",
        "url": "/layout/integrations",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "59",
    "name": "Catalogue Management",
    "icon": "fas fa-book",
    "submenu": [
      {
        "key": "74",
        "name": "Sales Channel",
        "icon": "fas fa-sitemap",
        "url": "/layout/ChannelDetails",
        "submenu": [

        ]
      },
      {
        "key": "97",
        "name": "Catalogue Item Master",
        "icon": "fas fa-luggage-cart",
        "url": "/layout/Supply Master",
        "submenu": [

        ]
      },
      {
        "key": "98",
        "name": "Master Catalogue",
        "icon": null,
        "url": "/layout/mastercatalogue",
        "submenu": [

        ]
      },
      {
        "key": "99",
        "name": "Shared Catalogues",
        "icon": null,
        "url": "/layout/sharedcatalogue",
        "submenu": [

        ]
      },
      {
        "key": "100",
        "name": "Price Overrides",
        "icon": null,
        "url": "/layout/priceoverrides",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "28",
    "name": "Sales",
    "icon": "fas fa-file-invoice-dollar",
    "submenu": [
      {
        "key": "10",
        "name": "Invoice",
        "icon": "fas fa-file-invoice",
        "url": "/layout/Sale Invoice",
        "submenu": [

        ]
      },
      {
        "key": "15",
        "name": "Sale Return",
        "icon": "fas fa-exchange-alt",
        "url": "/layout/Sale Return",
        "submenu": [

        ]
      },
      {
        "key": "20",
        "name": "Sale Credit Note",
        "icon": "fas fa-credit-card",
        "url": "/layout/Sale Credit Note",
        "submenu": [

        ]
      },
      {
        "key": "21",
        "name": "Sale Debit Note",
        "icon": "fas fa-credit-card",
        "url": "/layout/Sale Debit Note",
        "submenu": [

        ]
      },
      {
        "key": "65",
        "name": "Sale Order",
        "icon": "fas fa-plus-circle",
        "url": "/layout/Sale Order",
        "submenu": [

        ]
      },
      {
        "key": "66",
        "name": "Receipts",
        "icon": "fas fa-receipt",
        "url": "/layout/Sale Receipt",
        "submenu": [

        ]
      },
      {
        "key": "80",
        "name": "Quotation",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/Quotation",
        "submenu": [

        ]
      },
      {
        "key": "81",
        "name": "Overseas Sale",
        "icon": "fas fa-file-invoice",
        "url": "/layout/Overseas Sale",
        "submenu": [

        ]
      },
      {
        "key": "86",
        "name": "Advance Receipts",
        "icon": "fas fa-money-bill-wave",
        "url": "/layout/Advance Receipts",
        "submenu": [

        ]
      },
      {
        "key": "87",
        "name": "PoS",
        "icon": "fas fa-file-invoice",
        "url": "/layout/PoS",
        "submenu": [

        ]
      },
      {
        "key": "90",
        "name": "Workflow Order",
        "icon": "fas fa-angle-double-down",
        "url": "/layout/WorkflowOrderlist",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "29",
    "name": "Purchases",
    "icon": "fas fa-hand-holding-usd",
    "submenu": [
      {
        "key": "14",
        "name": "Purchase Return",
        "icon": "fas fa-exchange-alt",
        "url": "/layout/Purchase Return",
        "submenu": [

        ]
      },
      {
        "key": "22",
        "name": "Purchase Credit Note",
        "icon": "fas fa-credit-card",
        "url": "/layout/Purchase Credit Note",
        "submenu": [

        ]
      },
      {
        "key": "23",
        "name": "Purchase Debit Note",
        "icon": "fas fa-credit-card",
        "url": "/layout/Purchase Debit Note",
        "submenu": [

        ]
      },
      {
        "key": "35",
        "name": "Bills",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/Purchase Bills",
        "submenu": [

        ]
      },
      {
        "key": "36",
        "name": "Purchase Order",
        "icon": "fas fa-plus-circle",
        "url": "/layout/Purchase Order",
        "submenu": [

        ]
      },
      {
        "key": "37",
        "name": "Payments",
        "icon": "fas fa-money-bill-wave",
        "url": "/layout/Payments",
        "submenu": [

        ]
      },
      {
        "key": "75",
        "name": "Overseas purchase",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/Overseas Purchase",
        "submenu": [

        ]
      },
      {
        "key": "85",
        "name": "Advance Payments",
        "icon": "fas fa-receipt",
        "url": "/layout/Advance Payments",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "19",
    "name": "Accounts",
    "icon": "fas fa-database",
    "submenu": [
      {
        "key": "38",
        "name": "General Ledger",
        "icon": "far fa-user-circle",
        "url": "/layout/General Ledger",
        "submenu": [

        ]
      },
      {
        "key": "39",
        "name": "Opening Balance",
        "icon": "fas fa-book-open",
        "url": "/layout/Opening Balance",
        "submenu": [

        ]
      },
      {
        "key": "73",
        "name": "Accounts Setting",
        "icon": "fas fa-toolbox",
        "url": "/layout/AccountsSetting",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "43",
    "name": "E-Way Bill",
    "icon": "fas fa-sitemap",
    "submenu": [
      {
        "key": "44",
        "name": "E-Way Bill",
        "icon": "fas fa-file-invoice",
        "url": "/layout/E-Way Bill",
        "submenu": [

        ]
      },
      {
        "key": "45",
        "name": "NIC Configuration",
        "icon": "fas fa-info-circle",
        "url": "/layout/NIC Configuration",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "46",
    "name": "Data Management",
    "icon": "fas fa-server",
    "submenu": [
      {
        "key": "91",
        "name": "Data Import",
        "icon": "fas fa-sitemap",
        "url": "/layout/Data Import",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "53",
    "name": "Accounting Reports",
    "icon": "fas fa-file-signature",
    "submenu": [
      {
        "key": "54",
        "name": "Journal Reports",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/Journal Reports",
        "submenu": [

        ]
      },
      {
        "key": "55",
        "name": "Account Transactions",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/AccountTransactions",
        "submenu": [

        ]
      },
      {
        "key": "56",
        "name": "Trial Balance",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/TrialBalance",
        "submenu": [

        ]
      },
      {
        "key": "57",
        "name": "Profit & Loss (Schedule III)",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/profitandloss",
        "submenu": [

        ]
      },
      {
        "key": "58",
        "name": "Balance Sheet (Schedule III)",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/balancesheet",
        "submenu": [

        ]
      },
      {
        "key": "77",
        "name": "Cash/Bank Book",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/CashBankList",
        "submenu": [

        ]
      },
      {
        "key": "78",
        "name": "Trade Receivables",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/TradeReceivable",
        "submenu": [

        ]
      },
      {
        "key": "79",
        "name": "Trade Payables",
        "icon": "fas fa-file-invoice-dollar",
        "url": "/layout/TradePayable",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "63",
    "name": "Reports",
    "icon": "fas fa-sitemap",
    "submenu": [
      {
        "key": "64",
        "name": "Reports",
        "icon": "fas fa-book",
        "url": "/layout/Reports",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "82",
    "name": "Adjustments",
    "icon": "fas fa-sitemap",
    "submenu": [
      {
        "key": "83",
        "name": "Manual Journals",
        "icon": "fas fa-sitemap",
        "url": "/layout/journal",
        "submenu": [

        ]
      },
      {
        "key": "84",
        "name": "Expenses",
        "icon": "fas fa-sitemap",
        "url": "/layout/ExpenseList",
        "submenu": [

        ]
      }
    ]
  },
  {
    "key": "88",
    "name": "GST Returns",
    "icon": "fas fa-sitemap",
    "submenu": [
      {
        "key": "89",
        "name": "GST Returns",
        "icon": "fas fa-sitemap",
        "url": "/layout/GstReturns",
        "submenu": [

        ]
      }
    ]
  }
]

export const catSubMenues = [
  {
    "accessTypeId": 5003,
    "displayId": 1,
    "icon": "fas fa-sitemap",
    "id": "CatalogueItemMaster",
    "key": "97",
    "name": "Item Master",
    "parentId": "59",
    "submenu": [],
    "url": "/layout/Supply Master"
  },
  {
    "accessTypeId": 5003,
    "displayId": 1,
    "icon": "fas fa-sitemap",
    "id": "MasterCatalogue",
    "key": "98",
    "name": "Master Catalogue",
    "parentId": "59",
    "submenu": [],
    "url": "/layout/mastercatalogue"
  },
  {
    "accessTypeId": 5003,
    "displayId": 1,
    "icon": "fas fa-sitemap",
    "id": "SharedCatalogues",
    "key": "99",
    "name": "Shared Catalogues",
    "parentId": "59",
    "submenu": [],
    "url": "/layout/sharedcatalogue"
  },
  {
    "accessTypeId": 5003,
    "displayId": 1,
    "icon": "fas fa-sitemap",
    "id": "PriceOverrides",
    "key": "100",
    "name": "Price Overrides",
    "parentId": "59",
    "submenu": [],
    "url": "/layout/priceoverrides"
  }
]

export const BreadCrumns = [
  {
    name: "Users", breadcrumbs: [
      { name: "Users", text: "Users", key: "Users", path: "/layout/Users List" },
    ]
  },
  {
    name: "UsersAdd", breadcrumbs: [
      { name: "Users", text: "Users", key: "Users", path: "/layout/Users List" },
      { name: "New", text: "New", key: "New", path: "/layout/UserNewEdit" },
    ]
  },
  {
    name: "UsersEdit", breadcrumbs: [
      { name: "Users", text: "Users", key: "Users", path: "/layout/Users List" },
      { name: "", text: "", key: "", path: "/layout/UserNewEdit" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/UserNewEdit" },
    ]
  },
  {
    name: "Roles", breadcrumbs: [
      { name: "Roles", text: "Roles", key: "Roles", path: "/layout/Manage Role" },
    ]
  },
  {
    name: "Roles Add", breadcrumbs: [
      { name: "Roles", text: "Roles", key: "Roles", path: "/layout/Manage Role" },
      { name: "New", text: "New", key: "New", path: "/layout/Manage Role New" },
    ]
  },
  {
    name: "Roles Edit", breadcrumbs: [
      { name: "Roles", text: "Roles", key: "Roles", path: "/layout/Manage Role" },
      { name: "", text: "", key: "", path: "/layout/Manage Role New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Manage Role New" },
    ]
  },
  {
    name: "Configuration", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
    ]
  },
  {
    name: "Config General", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "General", text: "General", key: "General", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "1", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Number Series", text: "Number Series", key: "Number Series", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "1 New", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Number Series", text: "Number Series", key: "Number Series", path: "/layout/GeneralConfigNew" },
      { name: "New", text: "New", key: "New", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "1 Edit", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Number Series", text: "Number Series", key: "Number Series", path: "/layout/GeneralConfigNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "2", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Additional Config", text: "Additional Config", key: "Additional Config", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "2 New", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Additional Config", text: "Additional Config", key: "Additional Config", path: "/layout/GeneralConfigNew" },
      { name: "New", text: "New", key: "New", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "2 Edit", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Additional Config", text: "Additional Config", key: "Additional Config", path: "/layout/GeneralConfigNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "termsMaster", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Terms & Conditions", text: "Terms & Conditions", key: "Terms & Conditions", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "businessType", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "General Configuration", text: "General Configuration", key: "General Configuration", path: "/layout/GeneralConfigNew" },
      { name: "Business Type", text: "Business Type", key: "Business Type", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config Masters", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Masters", text: "Masters", key: "Masters", path: "/layout/GeneralConfigNew" },
      { name: "Item Master", text: "Item Master", key: "Item Master", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "bankDetails", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Masters", text: "Masters", key: "Masters", path: "/layout/GeneralConfigNew" },
      { name: "Bank Details", text: "Bank Details", key: "bankDetails", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Settings", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Transaction Settings", text: "Transaction Settings", key: "Transaction Settings", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config Invoicing", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Invoicing", text: "Invoicing", key: "Invoicing", path: "/layout/GeneralConfigNew" },
      { name: "Adjustments", text: "Adjustments", key: "Adjustments", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "5", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Invoicing", text: "Invoicing", key: "Invoicing", path: "/layout/GeneralConfigNew" },
      { name: "Invoice Builder", text: "Invoice Builder", key: "Invoice Builder", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config Inventory", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Inventory", text: "Inventory", key: "Inventory", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config Themes", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Themes", text: "Themes", key: "Themes", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config Workflow Management", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Workflow Management", text: "Workflow Management", key: "Workflow Management", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config Workflow Management New", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Workflow Management", text: "Workflow Management", key: "Workflow Management", path: "/layout/General Configuration" },
      { name: "New", text: "New", key: "New", path: "/layout/General Configuration" },
    ]
  },
  {
    name: "Config Workflow Management Edit", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Workflow Management", text: "Workflow Management", key: "Workflow Management", path: "/layout/General Configuration" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/General Configuration" },
    ]
  },
  {
    name: "org Logo", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "Upload Logo", text: "Upload Logo", key: "org Logo", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Config E-Invoice", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "E-Invoice", text: "E-Invoice", key: "org Logo", path: "/layout/GeneralConfigNew" },
      { name: "IRN Credentials", text: "IRN Credentials", key: "org Logo", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "TDS/TCS Setting", breadcrumbs: [
      { name: "Configuration", text: "Configuration", key: "Configuration", path: "/layout/General Configuration" },
      { name: "TDS/TCS Setting", text: "TDS/TCS Setting", key: "org Logo", path: "/layout/GeneralConfigNew" },
    ]
  },
  {
    name: "Party Master", breadcrumbs: [
      // { name: "Masters", text: "Master", key: "Master", path: null },
      { name: "Party Master (B2B)", text: "Party Master (B2B)", key: "Party Master (B2B)", path: "/layout/Party Master" },
    ]
  },
  {
    name: "Party Master New", breadcrumbs: [
      // { name: "Masters", text: "Master", key: "Master", path: null },
      { name: "Party Master (B2B)", text: "Party Master (B2B)", key: "Party Master (B2B)", path: "/layout/Party Master" },
      { name: "New", text: "New", key: "New", path: "/layout/Create Party" },
    ]
  },
  {
    name: "Party Master Edit", breadcrumbs: [
      // { name: "Masters", text: "Master", key: "Master", path: null },
      { name: "Party Master (B2B)", text: "Party Master (B2B)", key: "Party Master (B2B)", path: "/layout/Party Master" },
      { name: "", text: "", key: "", path: "/layout/Create Party" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Create Party" },
    ]
  },
  {
    name: "Party Master (B2C)", breadcrumbs: [
      // { name: "Masters", text: "Master", key: "Master", path: null },
      { name: "Party Master (B2C)", text: "Party Master (B2C)", key: "Party Master (B2C)", path: "/layout/Party Master" },
    ]
  },
  {
    name: "Party Master (B2C) New", breadcrumbs: [
      // { name: "Masters", text: "Master", key: "Master", path: null },
      { name: "Party Master (B2C)", text: "Party Master (B2C)", key: "Party Master (B2C)", path: "/layout/Party Master" },
      { name: "New", text: "New", key: "New", path: "/layout/Create Party B2C" },
    ]
  },
  {
    name: "Party Master (B2C) Edit", breadcrumbs: [
      // { name: "Masters", text: "Master", key: "Master", path: null },
      { name: "Party Master (B2C)", text: "Party Master (B2C)", key: "Party Master (B2C)", path: "/layout/Party Master" },
      { name: "", text: "", key: "", path: "/layout/Create Party B2C" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Create Party B2C" },
    ]
  },
  {
    name: "Unit of Measure", breadcrumbs: [
      { name: "Unit of Measure", text: "Unit of Measure", key: "Unit of Measure", path: "/layout/Unit Conversions" },
    ]
  },
  {
    name: "Unit of Measure New", breadcrumbs: [
      { name: "Unit of Measure", text: "Unit of Measure", key: "Unit of Measure", path: "/layout/Unit Conversions" },
      { name: "New", text: "New", key: "New", path: "/layout/Unit Conversions New" },
    ]
  },
  {
    name: "Unit of Measure Edit", breadcrumbs: [
      { name: "Unit of Measure", text: "Unit of Measure", key: "Unit of Measure", path: "/layout/Unit Conversions" },
      { name: "", text: "", key: "", path: "/layout/Unit Conversions New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Unit Conversions New" },
    ]
  },
  {
    name: "Locations", breadcrumbs: [
      { name: "Locations", text: "Locations", key: "Locations", path: "/layout/Manage Locations" },
    ]
  },
  {
    name: "Locations New", breadcrumbs: [
      { name: "Locations", text: "Locations", key: "Locations", path: "/layout/Manage Locations" },
      { name: "New", text: "New", key: "New", path: "/layout/newLocation" },
    ]
  },
  {
    name: "Locations Edit", breadcrumbs: [
      { name: "Locations", text: "Locations", key: "Locations", path: "/layout/Manage Locations" },
      { name: "", text: "", key: "", path: "/layout/newLocation" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/newLocation" },
    ]
  },
  {
    name: "Groups", breadcrumbs: [
      { name: "Groups", text: "Groups", key: "Groups", path: "/layout/GroupsList" },
    ]
  },
  {
    name: "Groups New", breadcrumbs: [
      { name: "Groups", text: "Groups", key: "Groups", path: "/layout/GroupsList" },
      { name: "New", text: "New", key: "New", path: "/layout/groupsedit" },
    ]
  },
  {
    name: "Groups Edit", breadcrumbs: [
      { name: "Groups", text: "Groups", key: "Groups", path: "/layout/GroupsList" },
      { name: "", text: "", key: "", path: "/layout/groupsedit" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/groupsedit" },
    ]
  },
  {
    name: "Subscriptions", breadcrumbs: [
      { name: "Subscriptions", text: "Subscriptions", key: "Subscriptions", path: "/layout/subscriptions" },
    ]
  },
  {
    name: "Inventory", breadcrumbs: [
      { name: "Inventory", text: "Inventory", key: "Item Master", path: "/layout/InventoryDashboard" },
    ]
  },
  {
    name: "Item Master", breadcrumbs: [
      { name: "Item Master", text: "Item Master", key: "Item Master", path: "/layout/Supply Master" },
    ]
  },
  {
    name: "Item Master New", breadcrumbs: [
      { name: "Item Master", text: "Item Master", key: "Item Master", path: "/layout/Supply Master" },
      { name: "New", text: "New", key: "New", path: "/layout/SupplyMasters" },
    ]
  },
  {
    name: "Item Master View", breadcrumbs: [
      { name: "Item Master", text: "Item Master", key: "Item Master", path: "/layout/Supply Master" },
      { name: "", text: "", key: "", path: "/layout/SupplyMasters" },
      { name: "View", text: "View", key: "View", path: "/layout/SupplyMasters" },
    ]
  },
  {
    name: "Item Master Edit", breadcrumbs: [
      { name: "Item Master", text: "Item Master", key: "Item Master", path: "/layout/Supply Master" },
      { name: "", text: "", key: "", path: "/layout/SupplyMasters" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/SupplyMasters" },
    ]
  },
  {
    name: "Categories", breadcrumbs: [
      { name: "Categories", text: "Categories", key: "Categories", path: "/layout/Category" },
    ]
  },
  {
    name: "Categories New", breadcrumbs: [
      { name: "Categories", text: "Categories", key: "Categories", path: "/layout/Category" },
      { name: "New", text: "New", key: "New", path: "/layout/Category" },
    ]
  },
  {
    name: "Categories Edit", breadcrumbs: [
      { name: "Categories", text: "Categories", key: "Categories", path: "/layout/Category" },
      { name: "", text: "", key: "", path: "/layout/Category" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Category" },
    ]
  },
  {
    name: "Attributes", breadcrumbs: [
      { name: "Attributes", text: "Attributes", key: "Attributes", path: "/layout/Attributes" },
    ]
  },
  {
    name: "Attributes New", breadcrumbs: [
      { name: "Attributes", text: "Attributes", key: "Attributes", path: "/layout/Attributes" },
      { name: "New", text: "New", key: "New", path: "/layout/Attributes" },
    ]
  },
  {
    name: "Attributes Edit", breadcrumbs: [
      { name: "Attributes", text: "Attributes", key: "Attributes", path: "/layout/Attributes" },
      { name: "", text: "", key: "", path: "/layout/Attributes" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Attributes" },
    ]
  },
  {
    name: "Sales Channel", breadcrumbs: [
      { name: "Sales Channel", text: "Sales Channel", key: "Sales Channel", path: "/layout/ChannelDetails" },
    ]
  },
  {
    name: "Sales Channel New", breadcrumbs: [
      { name: "Sales Channel", text: "Sales Channel", key: "Sales Channel", path: "/layout/ChannelDetails" },
      { name: "New", text: "New", key: "New", path: "/layout/AddChannelDetail" },
    ]
  },
  {
    name: "Sales Channel View", breadcrumbs: [
      { name: "Sales Channel", text: "Sales Channel", key: "Sales Channel", path: "/layout/ChannelDetails" },
      { name: "", text: "", key: "", path: "/layout/AddChannelDetail" },
      { name: "View", text: "View", key: "View", path: "/layout/AddChannelDetail" },
    ]
  },
  {
    name: "Sales Channel Edit", breadcrumbs: [
      { name: "Sales Channel", text: "Sales Channel", key: "Sales Channel", path: "/layout/ChannelDetails" },
      { name: "", text: "", key: "", path: "/layout/AddChannelDetail" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/AddChannelDetail" },
    ]
  },
  {
    name: "Master Catalogue View", breadcrumbs: [
      { name: "Master Catalogue", text: "Master Catalogue", key: "Master Catalogue", path: "/layout/mastercatalogue" },
      { name: "", text: "", key: "", path: "/layout/mastercatalogue" },
      { name: "View", text: "View", key: "View", path: "/layout/mastercatalogue" },
    ]
  },
  {
    name: "Shared Catalogues", breadcrumbs: [
      { name: "Shared Catalogues", text: "Shared Catalogues", key: "Shared Catalogues", path: "/layout/sharedcatalogue" },
      { name: "New", text: "New", key: "New", path: "/layout/sharedcatalogue" },
    ]
  },
  {
    name: "Shared Catalogues View", breadcrumbs: [
      { name: "Shared Catalogues", text: "Shared Catalogues", key: "Shared Catalogues", path: "/layout/sharedcatalogue" },
      { name: "", text: "", key: "", path: "/layout/sharedcatalogue" },
      { name: "View", text: "View", key: "View", path: "/layout/sharedcatalogue" },
    ]
  },
  {
    name: "Shared Catalogues Edit", breadcrumbs: [
      { name: "Shared Catalogues", text: "Shared Catalogues", key: "Shared Catalogues", path: "/layout/sharedcatalogue" },
      { name: "", text: "", key: "", path: "/layout/sharedcatalogue" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/sharedcatalogue" },
    ]
  },
  {
    name: "Price Overrides", breadcrumbs: [
      { name: "Price Overrides", text: "Price Overrides", key: "Price Overrides", path: "/layout/priceoverrides" },
    ]
  },
  {
    name: "Price Overrides New", breadcrumbs: [
      { name: "Price Overrides", text: "Price Overrides", key: "Price Overrides", path: "/layout/priceoverrides" },
      { name: "New", text: "New", key: "New", path: "/layout/PriceOverideCreation" },
    ]
  },
  {
    name: "Price Overrides Edit", breadcrumbs: [
      { name: "Price Overrides", text: "Price Overrides", key: "Price Overrides", path: "/layout/priceoverrides" },
      { name: "", text: "", key: "", path: "/layout/PriceOverideCreation" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/PriceOverideCreation" },
    ]
  },
  {
    name: "Manual Journals", breadcrumbs: [
      { name: "Manual Journals", text: "Manual Journals", key: "Manual Journals", path: "/layout/journal" },
    ]
  },
  {
    name: "Manual Journals New", breadcrumbs: [
      { name: "Manual Journals", text: "Manual Journals", key: "Manual Journals", path: "/layout/journal" },
      { name: "New", text: "New", key: "New", path: "/layout/journalNew" },
    ]
  },
  {
    name: "Manual Journals Edit", breadcrumbs: [
      { name: "Manual Journals", text: "Manual Journals", key: "Manual Journals", path: "/layout/journal" },
      { name: "", text: "", key: "", path: "/layout/journalNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/journalNew" },
    ]
  },
  {
    name: "Expenses", breadcrumbs: [
      { name: "Expenses", text: "Expenses", key: "Expenses", path: "/layout/ExpenseList" },
    ]
  },
  {
    name: "Expenses New", breadcrumbs: [
      { name: "Expenses", text: "Expenses", key: "Expenses", path: "/layout/ExpenseList" },
      { name: "New", text: "New", key: "New", path: "/layout/ExpenseNew" },
    ]
  },
  {
    name: "Expenses Edit", breadcrumbs: [
      { name: "Expenses", text: "Expenses", key: "Expenses", path: "/layout/ExpenseList" },
      { name: "", text: "", key: "", path: "/layout/ExpenseNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/ExpenseNew" },
    ]
  },
  {
    name: "Expenses View", breadcrumbs: [
      { name: "Expenses", text: "Expenses", key: "Expenses", path: "/layout/ExpenseList" },
      { name: "View", text: "View", key: "View", path: "/layout/ExpenseNew" },
    ]
  },
  {
    name: "GST Returns", breadcrumbs: [
      { name: "GST Returns", text: "GST Returns", key: "GST Returns", path: "/layout/GstReturns" },
    ]
  },
  {
    name: "Taxilla Cockpit", breadcrumbs: [
      { name: "Taxilla Cockpit", text: "Taxilla Cockpit", key: "Taxilla Cockpit", path: "/layout/TaxillaCockpit" },
    ]
  },
  {
    name: "GSTR-3B", breadcrumbs: [
      { name: "GST Returns", text: "GST Returns", key: "GST Returns", path: "/layout/GstReturns" },
      { name: "GSTR-3B", text: "GSTR-3B", key: "GSTR-3B", path: "/layout/gstr-3B" },
    ]
  },
  {
    name: "GSTR1", breadcrumbs: [
      { name: "GST Returns", text: "GST Returns", key: "GST Returns", path: "/layout/GstReturns" },
      { name: "GSTR1", text: "GSTR1", key: "GSTR1", path: "/layout/gstr1" },
    ]
  },
  // new screens
  {
    name: "Invoice(cashsale)", breadcrumbs: [
      { name: "Invoice (Cash Sale)", text: "Invoice (Cash Sale)", key: "Invoice(cashsale)", path: "/layout/Sale Invoice" },
    ]
  },
  {
    name: "Invoice(cashsale) New", breadcrumbs: [
      { name: "Invoice (Cash Sale)", text: "Invoice (Cash Sale)", key: "Invoice(cashsale)", path: "/layout/Sale Invoice" },
      { name: "New", text: "New", key: "New", path: "/layout/CreateCashSales" },
    ]
  },
  {
    name: "Invoice(cashsale) Edit", breadcrumbs: [
      { name: "Invoice (Cash Sale)", text: "Invoice (Cash Sale)", key: "Invoice(cashsale)", path: "/layout/Sale Invoice" },
      { name: "", text: "", key: "", path: "/layout/CreateCashSales" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/CreateCashSales" },
    ]
  },
  {
    name: "Invoice(cashsale) View", breadcrumbs: [
      { name: "Invoice (Cash Sale)", text: "Invoice (Cash Sale)", key: "Invoice(cashsale)", path: "/layout/Sale Invoice" },
      { name: "", text: "", key: "", path: "/layout/CashSalesView" },
      { name: "View", text: "View", key: "View", path: "/layout/CashSalesView" },
    ]
  },
  {
    name: "Invoice", breadcrumbs: [
      { name: "Invoice (Credit Sale)", text: "Invoice (Credit Sale)", key: "Invoice", path: "/layout/Sale Invoice" },
    ]
  },
  {
    name: "Invoice New", breadcrumbs: [
      { name: "Invoice (Credit Sale)", text: "Invoice (Credit Sale)", key: "Invoice", path: "/layout/Sale Invoice" },
      { name: "New", text: "New", key: "New", path: "/layout/Create Invoice" },
    ]
  },
  {
    name: "Invoice Edit", breadcrumbs: [
      { name: "Invoice (Credit Sale)", text: "Invoice (Credit Sale)", key: "Invoice", path: "/layout/Sale Invoice" },
      { name: "", text: "", key: "", path: "/layout/Create Invoice" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Create Invoice" },
    ]
  },
  {
    name: "Invoice View", breadcrumbs: [
      { name: "Invoice (Credit Sale)", text: "Invoice (Credit Sale)", key: "Invoice", path: "/layout/Sale Invoice" },
      { name: "", text: "", key: "", path: "/layout/saleview" },
      { name: "View", text: "View", key: "View", path: "/layout/saleview" },
    ]
  },
  {
    name: "Invoice RP", breadcrumbs: [
      { name: "Invoice (Credit Sale)", text: "Invoice (Credit Sale)", key: "Invoice", path: "/layout/Sale Invoice" },
      { name: "View", text: "View", key: "View", path: "/layout/saleview" },
      { name: "Record Payment", text: "Record Payment", key: "Record Payment", path: "/layout/saleview" },
    ]
  },
  // 6 itmes
  {
    name: "Sale Return", breadcrumbs: [
      { name: "Sale Return", text: "Sale Return", key: "Sale Return", path: "/layout/Sale Return" },
    ]
  },
  {
    name: "Sale Return New", breadcrumbs: [
      { name: "Sale Return", text: "Sale Return", key: "Sale Return", path: "/layout/Sale Return" },
      { name: "New", text: "New", key: "New", path: "/layout/salereturnnew" },
    ]
  },
  {
    name: "Sale Return Edit", breadcrumbs: [
      { name: "Sale Return", text: "Sale Return", key: "Sale Return", path: "/layout/Sale Return" },
      { name: "", text: "", key: "", path: "/layout/salereturnnew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/salereturnnew" },
    ]
  },
  {
    name: "Sale Return View", breadcrumbs: [
      { name: "Sale Return", text: "Sale Return", key: "Sale Return", path: "/layout/Sale Return" },
      { name: "", text: "", key: "", path: "/layout/ReturnView" },
      { name: "View", text: "View", key: "View", path: "/layout/ReturnView" },
    ]
  },
  {
    name: "Sale Credit Note", breadcrumbs: [
      { name: "Sale Credit Note", text: "Sale Credit Note", key: "Sale Credit Note", path: "/layout/Sale Credit Note" },
    ]
  },
  {
    name: "Sale Credit Note New", breadcrumbs: [
      { name: "Sale Credit Note", text: "Sale Credit Note", key: "Sale Credit Note", path: "/layout/Sale Credit Note" },
      { name: "New", text: "New", key: "New", path: "/layout/SaleCreditNoteNew" },
    ]
  },
  {
    name: "Sale Credit Note Edit", breadcrumbs: [
      { name: "Sale Credit Note", text: "Sale Credit Note", key: "Sale Credit Note", path: "/layout/Sale Credit Note" },
      { name: "", text: "", key: "", path: "/layout/SaleCreditNoteNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/SaleCreditNoteNew" },
    ]
  },
  {
    name: "Sale Credit Note View", breadcrumbs: [
      { name: "Sale Credit Note", text: "Sale Credit Note", key: "Sale Credit Note", path: "/layout/Sale Credit Note" },
      { name: "", text: "", key: "", path: "/layout/creditview" },
      { name: "View", text: "View", key: "View", path: "/layout/creditview" },
    ]
  },
  {
    name: "Sale Debit Note", breadcrumbs: [
      { name: "Sale Debit Note", text: "Sale Debit Note", key: "Sale Debit Note", path: "/layout/Sale Debit Note" },
    ]
  },
  {
    name: "Sale Debit Note New", breadcrumbs: [
      { name: "Sale Debit Note", text: "Sale Debit Note", key: "Sale Debit Note", path: "/layout/Sale Debit Note" },
      { name: "New", text: "New", key: "New", path: "/layout/saleDebitNew" },
    ]
  },
  {
    name: "Sale Debit Note Edit", breadcrumbs: [
      { name: "Sale Debit Note", text: "Sale Debit Note", key: "Sale Debit Note", path: "/layout/Sale Debit Note" },
      { name: "", text: "", key: "", path: "/layout/saleDebitNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/saleDebitNew" },
    ]
  },
  {
    name: "Sale Debit Note View", breadcrumbs: [
      { name: "Sale Debit Note", text: "Sale Debit Note", key: "Sale Debit Note", path: "/layout/Sale Debit Note" },
      { name: "", text: "", key: "", path: "/layout/debitview" },
      { name: "View", text: "View", key: "View", path: "/layout/debitview" },
    ]
  },
  {
    name: "Sale Order", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
    ]
  },
  {
    name: "Sale Order New", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
      { name: "New", text: "New", key: "New", path: "/layout/SaleOrderNew" },
    ]
  },
  {
    name: "Sale Order Edit", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
      { name: "", text: "", key: "", path: "/layout/SaleOrderNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/SaleOrderNew" },
    ]
  },
  {
    name: "Sale Order View", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
      { name: "", text: "", key: "", path: "/layout/SaleOrderView" },
      { name: "View", text: "View", key: "View", path: "/layout/SaleOrderView" },
    ]
  },
  {
    name: "Workflow Order", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
    ]
  },
  {
    name: "Workflow Order New", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
      { name: "New", text: "New", key: "New", path: "/layout/SaleOrderNew" },
    ]
  },
  {
    name: "Workflow Order Edit", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
      { name: "", text: "", key: "", path: "/layout/SaleOrderNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/SaleOrderNew" },
    ]
  },
  {
    name: "Workflow Order View", breadcrumbs: [
      { name: "Sale Order", text: "Sale Order", key: "Sale Order", path: "/layout/Sale Order" },
      { name: "", text: "", key: "", path: "/layout/SaleOrderView" },
      { name: "View", text: "View", key: "View", path: "/layout/SaleOrderView" },
    ]
  },
  {
    name: "Receipts", breadcrumbs: [
      { name: "Receipts", text: "Receipts", key: "Receipts", path: "/layout/Sale Receipt" },
    ]
  },
  {
    name: "Receipts New", breadcrumbs: [
      { name: "Receipts", text: "Receipts", key: "Receipts", path: "/layout/Sale Receipt" },
      { name: "New", text: "New", key: "New", path: "/layout/Sale Receipt New" },
    ]
  },
  {
    name: "Receipts Edit", breadcrumbs: [
      { name: "Receipts", text: "Receipts", key: "Receipts", path: "/layout/Sale Receipt" },
      { name: "", text: "", key: "", path: "/layout/Sale Receipt New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Sale Receipt New" },
    ]
  },
  {
    name: "Receipts View", breadcrumbs: [
      { name: "Receipts", text: "Receipts", key: "Receipts", path: "/layout/Sale Receipt" },
      { name: "", text: "", key: "", path: "/layout/Sale Receipt Preview" },
      { name: "View", text: "View", key: "View", path: "/layout/Sale Receipt Preview" },
    ]
  },
  {
    name: "Quotation", breadcrumbs: [
      { name: "Quotation", text: "Quotation", key: "Quotation", path: "/layout/Quotation" },
    ]
  },
  {
    name: "Quotation New", breadcrumbs: [
      { name: "Quotation", text: "Quotation", key: "Quotation", path: "/layout/Quotation" },
      { name: "New", text: "New", key: "New", path: "/layout/QuotationParent" },
    ]
  },
  {
    name: "Quotation Edit", breadcrumbs: [
      { name: "Quotation", text: "Quotation", key: "Quotation", path: "/layout/Quotation" },
      { name: "", text: "", key: "", path: "/layout/QuotationParent" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/QuotationParent" },
    ]
  },
  {
    name: "Quotation View", breadcrumbs: [
      { name: "Quotation", text: "Quotation", key: "Quotation", path: "/layout/Quotation" },
      { name: "", text: "", key: "", path: "/layout/QuationView" },
      { name: "View", text: "View", key: "View", path: "/layout/QuationView" },
    ]
  },
  {
    name: "Overseas Sale", breadcrumbs: [
      { name: "Overseas Sale", text: "Overseas Sale", key: "Overseas Sale", path: "/layout/Overseas Sale" },
    ]
  },
  {
    name: "Overseas Sale New", breadcrumbs: [
      { name: "Overseas Sale", text: "Overseas Sale", key: "Overseas Sale", path: "/layout/Overseas Sale" },
      { name: "New", text: "New", key: "New", path: "/layout/OverseasSaleNew" },
    ]
  },
  {
    name: "Overseas Sale Edit", breadcrumbs: [
      { name: "Overseas Sale", text: "Overseas Sale", key: "Overseas Sale", path: "/layout/Overseas Sale" },
      { name: "", text: "", key: "", path: "/layout/OverseasSaleNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/OverseasSaleNew" },
    ]
  },
  {
    name: "Overseas Sale View", breadcrumbs: [
      { name: "Overseas Sale", text: "Overseas Sale", key: "Overseas Sale", path: "/layout/Overseas Sale" },
      { name: "", text: "", key: "", path: "/layout/OverseasSaleView" },
      { name: "View", text: "View", key: "View", path: "/layout/OverseasSaleView" },
    ]
  },
  {
    name: "Overseas Sale RP", breadcrumbs: [
      { name: "Overseas Sale", text: "Overseas Sale", key: "Overseas Sale", path: "/layout/Overseas Sale" },
      { name: "View", text: "View", key: "View", path: "/layout/OverseasSaleView" },
      { name: "Record Payment", text: "Record Payment", key: "Record Payment", path: "/layout/OverseasSaleView" },
    ]
  },
  // 6 items
  {
    name: "Advance Receipts", breadcrumbs: [
      { name: "Advance Receipts", text: "Advance Receipts", key: "Advance Receipts", path: "/layout/Advance Receipts" },
    ]
  },
  {
    name: "Advance Receipts New", breadcrumbs: [
      { name: "Advance Receipts", text: "Advance Receipts", key: "Advance Receipts", path: "/layout/Advance Receipts" },
      { name: "New", text: "New", key: "New", path: "/layout/AdvReceiptsNew" },
    ]
  },
  {
    name: "Advance Receipts Edit", breadcrumbs: [
      { name: "Advance Receipts", text: "Advance Receipts", key: "Advance Receipts", path: "/layout/Advance Receipts" },
      { name: "", text: "", key: "", path: "/layout/AdvReceiptsNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/AdvReceiptsNew" },
    ]
  },
  {
    name: "Advance Receipts View", breadcrumbs: [
      { name: "Advance Receipts", text: "Advance Receipts", key: "Advance Receipts", path: "/layout/Advance Receipts" },
      { name: "", text: "", key: "", path: "/layout/AdvReceiptsView" },
      { name: "View", text: "View", key: "View", path: "/layout/AdvReceiptsView" },
    ]
  },
  {
    name: "PoS", breadcrumbs: [
      { name: "PoS", text: "PoS", key: "PoS", path: "/layout/PoS" },
    ]
  },
  {
    name: "PoS New", breadcrumbs: [
      { name: "PoS", text: "PoS", key: "PoS", path: "/layout/PoS" },
      { name: "New", text: "New", key: "New", path: "/layout/Create B2C Invoice" },
    ]
  },
  {
    name: "PoS Edit", breadcrumbs: [
      { name: "PoS", text: "PoS", key: "PoS", path: "/layout/PoS" },
      { name: "", text: "", key: "", path: "/layout/Create B2C Invoice" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Create B2C Invoice" },
    ]
  },
  {
    name: "PoS View", breadcrumbs: [
      { name: "PoS", text: "PoS", key: "PoS", path: "/layout/PoS" },
      { name: "", text: "", key: "", path: "/layout/Sale B2C View" },
      { name: "View", text: "View", key: "View", path: "/layout/Sale B2C View" },
    ]
  },
  // {
  //   name: "Workflow Order", breadcrumbs: [
  //     { name: "Workflow Order", text: "Workflow Order", key: "Workflow Order", path: "/layout/WorkflowOrderlist" },
  //   ]
  // },
  // {
  //   name: "Workflow Order New", breadcrumbs: [
  //     { name: "Workflow Order", text: "Workflow Order", key: "Workflow Order", path: "/layout/WorkflowOrderlist" },
  //     { name: "New", text: "New", key: "New", path: "/layout/WorkflowOrderNew" },
  //   ]
  // },
  // {
  //   name: "Workflow Order Edit", breadcrumbs: [
  //     { name: "Workflow Order", text: "Workflow Order", key: "Workflow Order", path: "/layout/WorkflowOrderlist" },
  //     { name: "", text: "", key: "", path: "/layout/WorkflowOrderNew" },
  //     { name: "Edit", text: "Edit", key: "Edit", path: "/layout/WorkflowOrderNew" },
  //   ]
  // },
  // {
  //   name: "Workflow Order View", breadcrumbs: [
  //     { name: "Workflow Order", text: "Workflow Order", key: "Workflow Order", path: "/layout/WorkflowOrderlist" },
  //     { name: "", text: "", key: "", path: "/layout/flowOrderView" },
  //     { name: "View", text: "View", key: "View", path: "/layout/flowOrderView" },
  //   ]
  // },
  {
    name: "Purchase Return", breadcrumbs: [
      { name: "Purchase Return", text: "Purchase Return", key: "Purchase Return", path: "/layout/Purchase Return" },
    ]
  },
  {
    name: "Purchase Return New", breadcrumbs: [
      { name: "Purchase Return", text: "Purchase Return", key: "Purchase Return", path: "/layout/Purchase Return" },
      { name: "New", text: "New", key: "New", path: "/layout/Purchase Return New" },
    ]
  },
  {
    name: "Purchase Return New", breadcrumbs: [
      { name: "Purchase Return", text: "Purchase Return", key: "Purchase Return", path: "/layout/Purchase Return" },
      { name: "New", text: "New", key: "New", path: "/layout/Purchase Return New" },
    ]
  },
  {
    name: "Purchase Return Edit", breadcrumbs: [
      { name: "Purchase Return", text: "Purchase Return", key: "Purchase Return", path: "/layout/Purchase Return" },
      { name: "", text: "", key: "", path: "/layout/Purchase Return New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Purchase Return New" },
    ]
  },
  {
    name: "Purchase Return View", breadcrumbs: [
      { name: "Purchase Return", text: "Purchase Return", key: "Purchase Return", path: "/layout/Purchase Return" },
      { name: "", text: "", key: "", path: "/layout/Purchase Template New" },
      { name: "View", text: "View", key: "View", path: "/layout/Purchase Template New" },
    ]
  },
  {
    name: "Purchase Credit Note", breadcrumbs: [
      { name: "Purchase Credit Note", text: "Purchase Credit Note", key: "Purchase Credit Note", path: "/layout/Purchase Credit Note" },
    ]
  },
  {
    name: "Purchase Credit Note New", breadcrumbs: [
      { name: "Purchase Credit Note", text: "Purchase Credit Note", key: "Purchase Credit Note", path: "/layout/Purchase Credit Note" },
      { name: "New", text: "New", key: "New", path: "/layout/Purchase Credit Note New" },
    ]
  },
  {
    name: "Purchase Credit Note Edit", breadcrumbs: [
      { name: "Purchase Credit Note", text: "Purchase Credit Note", key: "Purchase Credit Note", path: "/layout/Purchase Credit Note" },
      { name: "", text: "", key: "", path: "/layout/Purchase Credit Note New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Purchase Credit Note New" },
    ]
  },
  {
    name: "Purchase Credit Note View", breadcrumbs: [
      { name: "Purchase Credit Note", text: "Purchase Credit Note", key: "Purchase Credit Note", path: "/layout/Purchase Credit Note" },
      { name: "", text: "", key: "", path: "/layout/Purchase Credit View" },
      { name: "View", text: "View", key: "View", path: "/layout/Purchase Credit View" },
    ]
  },
  {
    name: "Purchase Debit Note", breadcrumbs: [
      { name: "Purchase Debit Note", text: "Purchase Debit Note", key: "Purchase Debit Note", path: "/layout/Purchase Debit Note" },
    ]
  },
  {
    name: "Purchase Debit Note New", breadcrumbs: [
      { name: "Purchase Debit Note", text: "Purchase Debit Note", key: "Purchase Debit Note", path: "/layout/Purchase Debit Note" },
      { name: "New", text: "New", key: "New", path: "/layout/Purchase Debit Note New" },
    ]
  },
  {
    name: "Purchase Debit Note Edit", breadcrumbs: [
      { name: "Purchase Debit Note", text: "Purchase Debit Note", key: "Purchase Debit Note", path: "/layout/Purchase Debit Note" },
      { name: "", text: "", key: "", path: "/layout/Purchase Debit Note New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Purchase Debit Note New" },
    ]
  },
  {
    name: "Purchase Debit Note View", breadcrumbs: [
      { name: "Purchase Debit Note", text: "Purchase Debit Note", key: "Purchase Debit Note", path: "/layout/Purchase Debit Note" },
      { name: "", text: "", key: "", path: "/layout/Purchase Debit View" },
      { name: "View", text: "View", key: "View", path: "/layout/Purchase Debit View" },
    ]
  },
  {
    name: "Purchase Debit Note RP", breadcrumbs: [
      { name: "Purchase Debit Note", text: "Purchase Debit Note", key: "Purchase Debit Note", path: "/layout/Purchase Debit Note" },
      { name: "View", text: "View", key: "View", path: "/layout/Purchase Debit View" },
      { name: "Receive Payment", text: "Receive Payment", key: "View", path: "/layout/Purchase Debit View" },
    ]
  },
  {
    name: "Bills", breadcrumbs: [
      { name: "Bills", text: "Bills", key: "Bills", path: "/layout/Purchase Bills" },
    ]
  },
  {
    name: "Purchase Bills New", breadcrumbs: [
      { name: "Bills", text: "Bills", key: "Bills", path: "/layout/Purchase Bills" },
      { name: "New", text: "New", key: "New", path: "/layout/Purchase Bill New" },
    ]
  },
  {
    name: "Purchase Bills Edit", breadcrumbs: [
      { name: "Bills", text: "Bills", key: "Bills", path: "/layout/Purchase Bills" },
      { name: "", text: "", key: "", path: "/layout/Purchase Bill New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Purchase Bill New" },
    ]
  },
  {
    name: "Purchase Bills View", breadcrumbs: [
      { name: "Bills", text: "Bills", key: "Bills", path: "/layout/Purchase Bills" },
      { name: "", text: "", key: "", path: "/layout/PurchaseView" },
      { name: "View", text: "View", key: "View", path: "/layout/PurchaseView" },
    ]
  },
  {
    name: "Purchase Order", breadcrumbs: [
      { name: "Purchase Order", text: "Purchase Order", key: "Purchase Order", path: "/layout/Purchase Order" },
    ]
  },
  {
    name: "Purchase Order New", breadcrumbs: [
      { name: "Purchase Order", text: "Purchase Order", key: "Purchase Order", path: "/layout/Purchase Order" },
      { name: "New", text: "New", key: "New", path: "/layout/Purchase Order New" },
    ]
  },
  {
    name: "Purchase Order Edit", breadcrumbs: [
      { name: "Purchase Order", text: "Purchase Order", key: "Purchase Order", path: "/layout/Purchase Order" },
      { name: "", text: "", key: "", path: "/layout/Purchase Order New" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/Purchase Order New" },
    ]
  },
  {
    name: "Purchase Order View", breadcrumbs: [
      { name: "Purchase Order", text: "Purchase Order", key: "Purchase Order", path: "/layout/Purchase Order" },
      { name: "", text: "", key: "", path: "/layout/PurchaseOrderview" },
      { name: "View", text: "View", key: "View", path: "/layout/PurchaseOrderview" },
    ]
  },
  {
    name: "Payments", breadcrumbs: [
      { name: "Payments", text: "Payments", key: "Payments", path: "/layout/Payments" },
    ]
  },
  {
    name: "Payments New", breadcrumbs: [
      { name: "Payments", text: "Payments", key: "Payments", path: "/layout/Payments" },
      { name: "New", text: "New", key: "New", path: "/layout/paymentnew" },
    ]
  },
  {
    name: "Payments Edit", breadcrumbs: [
      { name: "Payments", text: "Payments", key: "Payments", path: "/layout/Payments" },
      { name: "", text: "", key: "", path: "/layout/paymentnew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/paymentnew" },
    ]
  },
  {
    name: "Payments View", breadcrumbs: [
      { name: "Payments", text: "Payments", key: "Payments", path: "/layout/Payments" },
      { name: "", text: "", key: "", path: "/layout/paymentnew" },
      { name: "View", text: "View", key: "View", path: "/layout/paymentPreview" },
    ]
  },
  {
    name: "Overseas purchase", breadcrumbs: [
      { name: "Overseas purchase", text: "Overseas purchase", key: "Overseas purchase", path: "/layout/Overseas Purchase" },
    ]
  },
  {
    name: "Overseas purchase New", breadcrumbs: [
      { name: "Overseas purchase", text: "Overseas purchase", key: "Overseas purchase", path: "/layout/Overseas Purchase" },
      { name: "New", text: "New", key: "New", path: "/layout/OverseasPurchaseNew" },
    ]
  },
  {
    name: "Overseas purchase Edit", breadcrumbs: [
      { name: "Overseas purchase", text: "Overseas purchase", key: "Overseas purchase", path: "/layout/Overseas Purchase" },
      { name: "", text: "", key: "", path: "/layout/OverseasPurchaseNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/OverseasPurchaseNew" },
    ]
  },
  {
    name: "Overseas purchase View", breadcrumbs: [
      { name: "Overseas purchase", text: "Overseas purchase", key: "Overseas purchase", path: "/layout/Overseas Purchase" },
      { name: "View", text: "View", key: "View", path: "/layout/OverseasPurchaseView" },
    ]
  },
  {
    name: "Overseas purchase RP", breadcrumbs: [
      { name: "Overseas purchase", text: "Overseas purchase", key: "Overseas purchase", path: "/layout/Overseas Purchase" },
      { name: "View", text: "View", key: "View", path: "/layout/OverseasPurchaseView" },
      { name: "Record Payment", text: "Record Payment", key: "Record Payment", path: "/layout/OverseasPurchaseView" },
    ]
  },
  {
    name: "Advance Payments", breadcrumbs: [
      { name: "Advance Payments", text: "Advance Payments", key: "Advance Payments", path: "/layout/Advance Payments" },
    ]
  },
  {
    name: "Advance Payments New", breadcrumbs: [
      { name: "Advance Payments", text: "Advance Payments", key: "Advance Payments", path: "/layout/Advance Payments" },
      { name: "New", text: "New", key: "New", path: "/layout/AdvPaymentsNew" },
    ]
  },
  {
    name: "Advance Payments Edit", breadcrumbs: [
      { name: "Advance Payments", text: "Advance Payments", key: "Advance Payments", path: "/layout/Advance Payments" },
      { name: "", text: "", key: "", path: "/layout/AdvPaymentsNew" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/AdvPaymentsNew" },
    ]
  },
  {
    name: "Advance Payments View", breadcrumbs: [
      { name: "Advance Payments", text: "Advance Payments", key: "Advance Payments", path: "/layout/Advance Payments" },
      { name: "", text: "", key: "", path: "/layout/AdvPaymentsView" },
      { name: "View", text: "View", key: "View", path: "/layout/AdvPaymentsView" },
    ]
  },
  {
    name: "Organisation Stock Levels", breadcrumbs: [
      { name: "Organisation Stock Levels", text: "Organisation Stock Levels", key: "Organisation Stock Levels", path: "/layout/allopeningstock" },
    ]
  },
  {
    name: "Client Users", breadcrumbs: [
      { name: "Client Users", text: "Client Users", key: "Client Users", path: "/layout/ClientUsers" },
    ]
  },
  {
    name: "Ledgers", breadcrumbs: [
      { name: "Ledgers", text: "Ledgers", key: "Ledgers", path: "/layout/Ledgers" },
    ]
  },
  {
    name: "Ledgers New", breadcrumbs: [
      { name: "Ledgers", text: "Ledgers", key: "Ledgers", path: "/layout/Ledgers" },
      { name: "New", text: "New", key: "New", path: "/layout/LedgerCreation" },
    ]
  },
  {
    name: "Ledgers Edit", breadcrumbs: [
      { name: "Ledgers", text: "Ledgers", key: "Ledgers", path: "/layout/Ledgers" },
      { name: "", text: "", key: "", path: "/layout/LedgerCreation" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/LedgerCreation" },
    ]
  },

  {
    name: "Chart of Accounts", breadcrumbs: [
      { name: "Chart of Accounts", text: "Chart of Accounts", key: "Chart of Accounts", path: "/layout/ChartOfAccounts" },
    ]
  },
  {
    name: "Chart of Accounts New", breadcrumbs: [
      { name: "Chart of Accounts", text: "Chart of Accounts", key: "Chart of Accounts", path: "/layout/ChartOfAccounts" },
      { name: "New", text: "New", key: "New", path: "/layout/ChartOfAccountsNewEdit" },
    ]
  },
  {
    name: "Chart of Accounts Edit", breadcrumbs: [
      { name: "Chart of Accounts", text: "Chart of Accounts", key: "Chart of Accounts", path: "/layout/ChartOfAccounts" },
      { name: "", text: "", key: "", path: "/layout/ChartOfAccountsNewEdit" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/ChartOfAccountsNewEdit" },
    ]
  },
  {
    name: "Journal vouchers", breadcrumbs: [
      { name: "Journal vouchers", text: "Journal vouchers", key: "Journal vouchers", path: "/layout/JournalVouchers" },
    ]
  },
  {
    name: "Journal vouchers New", breadcrumbs: [
      { name: "Journal vouchers", text: "Journal vouchers", key: "Journal vouchers", path: "/layout/JournalVouchers" },
      { name: "New", text: "New", key: "New", path: "/layout/JournalVoucherCreation" },
    ]
  },
  {
    name: "Journal vouchers Edit", breadcrumbs: [
      { name: "Journal vouchers", text: "Journal vouchers", key: "Journal vouchers", path: "/layout/JournalVouchers" },
      { name: "", text: "", key: "", path: "/layout/JournalVoucherCreation" },
      { name: "Edit", text: "Edit", key: "Edit", path: "/layout/JournalVoucherCreation" },
    ]
  },
  {
    name: "Outstanding Creditors", breadcrumbs: [
      { name: "Outstanding Creditors", text: "Outstanding Creditors", key: "Outstanding Creditors", path: "/layout/OutStandingCreditors" },
    ]
  },
  {
    name: "Outstanding Debitors", breadcrumbs: [
      { name: "Outstanding Debitors", text: "Outstanding Debitors", key: "Outstanding Debitors", path: "/layout/OutStandingDebitors" },
    ]
  },
  {
    name: "General Ledger", breadcrumbs: [
      { name: "General Ledger", text: "General Ledger", key: "General Ledger", path: "/layout/Journal Reports" },
    ]
  },
  {
    name: "General Ledger", breadcrumbs: [
      { name: "General Ledger", text: "General Ledger", key: "General Ledger", path: "/layout/Journal Reports" },
      { name: "", text: "", key: "", path: "/layout/Journal Reports" },
    ]
  },
  {
    name: "Trial Balance", breadcrumbs: [
      { name: "Trial Balance", text: "Trial Balance", key: "Trial Balance", path: "/layout/TrialBalance" },
    ]
  },
  {
    name: "Trial Balance Ledger", breadcrumbs: [
      { name: "Trial Balance", text: "Trial Balance", key: "Trial Balance", path: "/layout/TrialBalance" },
      { name: "", text: "", key: "", path: "/layout/TrialLedgerTransactions" },
    ]
  },
  {
    name: "Profit & Loss", breadcrumbs: [
      { name: "Profit & Loss", text: "Profit & Loss", key: "Profit & Loss", path: "/layout/profitandloss" },
    ]
  },
  {
    name: "Profit & Loss Ledger", breadcrumbs: [
      { name: "Profit & Loss", text: "Profit & Loss", key: "Profit & Loss", path: "/layout/profitandloss" },
      { name: "", text: "", key: "", path: "/layout/profitandlossLedgerTransactions" },
    ]
  },
  {
    name: "Balance Sheet", breadcrumbs: [
      { name: "Balance Sheet", text: "Balance Sheet", key: "Balance Sheet", path: "/layout/balancesheet" },
    ]
  },
  {
    name: "Balance Sheet Sub", breadcrumbs: [
      { name: "Balance Sheet", text: "Balance Sheet", key: "Balance Sheet", path: "/layout/balancesheet" },
      { name: "", text: "", key: "", path: "/layout/balanceSubsheet" },
    ]
  },
  {
    name: "Balance Sheet Ledger", breadcrumbs: [
      { name: "Balance Sheet", text: "Balance Sheet", key: "Balance Sheet", path: "/layout/balancesheet" },
      { name: "", text: "", key: "", path: "/layout/balanceLedgerTransations" },
    ]
  },
]