import { Empty, Tooltip } from 'antd';
import React, { Component } from 'react'
import { getTransactionDetailForInvoice } from '../_services/sale.service';
import AdqButton from './commonControls/AdqButton/AdqButton';
import { notificaitons } from './Layout.model';

export default class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notificaitonObj: props.stateDashObj ? props.stateDashObj : props.stateObj.notificaitonObj,
            
        }
    }

    dismissNotification = (e, item, type) => {
        let listofNotifications = this.state.notificaitonObj;
        if (type === null) {
          let findIndex = listofNotifications.findIndex(x => x.id === item.id);
          if (findIndex !== -1) {
            listofNotifications.splice(findIndex, 1);
          }
        } else if (type === "All") {
          listofNotifications = [];
        }
        this.setState({ notificaitonObj: listofNotifications })
    }

    handleNotifyLink = async(e, Obj)=> {


        if(Obj.actionType === 1 && Obj.link){
          let linkData = Obj.link.split(",")
          console.log(linkData)
          
          let OrdObj = {
            TransactionId : linkData[0].trim(),
            TransactionNumber : linkData[1].trim(),
            OrgCode : linkData[2].trim(),
            SubOrgCode : linkData[3].trim(),
          }
          
          console.log(OrdObj)
    
          await this.handleOrderView(OrdObj, Obj)
    
        }
    }
    
    handleOrderView = async(OrdObj,linkObj )=>{
        await getTransactionDetailForInvoice(OrdObj).then( async(response) => {
          if (response.status === 200) {                 
              
              let Result = response.data.result.details;
              let AccoutingData = this.state.AccoutingData
              if ( Result) {
              //   window.history.pushState({
              //     pathname: "/layout/SaleOrderView",
              //     ViewInvoice: Result,
              //     TdsAccountingdata: AccoutingData,
              // }, 
              // '', '/layout/SaleOrderView');
                  
              //this.props.history.push("/layout/SaleOrderView")
            //   window.history.pushState( "", "", "/layout/SaleOrderView")
              this.props.history.push({
                  pathname: "/layout/SaleOrderView",
                  ViewInvoice: Result,
                  TdsAccountingdata: AccoutingData,
                  notifyLinkObj : linkObj,
                  fromNotifyLink : true,
                });
              }
          }
        })
    }


  render() {
    const { notificaitonObj, } = this.state;
    return (
      
        <div className="notificationBox">
                <h6>Notifications</h6>
                {notificaitonObj && notificaitonObj.length > 0 ?
                  <>
                    <ul>
                      {notificaitonObj.map((items, id) => {
                        return (
                          <li key={id}>
                            <Tooltip title={items.message}>
                              <span><i className="fas fa-exclamation-circle" style={{ color: this.state.color, fontSize: "12px", marginRight: "5px" }}></i></span>
                            </Tooltip>
                            <p>{items.message}</p>
                            {items.link ?
                              <>
                                <a className="verifylinkPosition mr-4"
                                  style={{ float: "right" }} href="" onClick={(e) => { this.handleNotifyLink(e, items) }} >Link</a>
                              </> : ''}

                            <span className="noficitaionDismiss" onClick={(e) => { this.dismissNotification(e, items, null) }}>&#10005;</span>
                          </li>
                        )
                      })
                      }
                    </ul>
                    <AdqButton buttonData={notificationBtnObj} buttonAction={(e) => { this.dismissNotification(e, null, "All") }} />
                  </>
                  :
                  <div style={{ margin: "80px 0" }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <span className="text-center">
                          <b>Hurray !</b><br />
                          you are all caught up !
                        </span>
                      }
                    />
                  </div>
                }
              </div>
    )
  }
}


export const notificationBtnObj = {
    name: "dismissall",
    ctrltype: "btn",
    labels: "",
    type: "default",
    text: "Dismiss All",
    icon: 'addIcon',
    disabled: false,
    id: "n_dismiss_Btn"
  }