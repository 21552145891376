import { ORGBANKAPI_REQUEST, ORGBANKAPI_SUCCESS, ORGBANKGETAPI_REQUEST,} from "../actions";

export const orgBankApi_request= (request) => {
  return {
    payload: request,
    type: ORGBANKAPI_REQUEST,
  }
};

export const orgBankApi_success= (request) => {
  return {
    payload: request,
    type: ORGBANKAPI_SUCCESS,
  }
};

export const OrgBankGetApi_request= (request) => {
  return {
    payload: request,
    type: ORGBANKGETAPI_REQUEST,
  }
};
