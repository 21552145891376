import { all, fork, takeLatest } from "redux-saga/effects";
import { SAVEBREADCRUMB } from "../actions";
import { saveBreadCrumb } from "./action";
  
export function* watchSaveBreadCrumb() {
  yield takeLatest(SAVEBREADCRUMB, addBreadCrumb);
}

function* addBreadCrumb({ payload }) {
    saveBreadCrumb();
}

export default function* rootSaga() {
    yield all([
      fork(watchSaveBreadCrumb),
    ]);
  }
  

 