import { all, fork, takeLatest } from "redux-saga/effects";
import { BALANCE_SHEET_ARRAY } from "../actions";
import { balanceSheetList,removeLastItem } from "./action";
  
export function* watchSaveBreadCrumb() {
  yield takeLatest(BALANCE_SHEET_ARRAY, addBalanceSheetList);
}

function* addBalanceSheetList({ payload }) {
    if(payload)
    balanceSheetList();
    else removeLastItem()
}

export default function* rootSaga() {
    yield all([
      fork(watchSaveBreadCrumb),
    ]);
  }
  

 