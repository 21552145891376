import { BALANCE_SHEET_ARRAY,CLEAR_ARRAY,REMOVE_LAST_ITEM } from "../actions";

export const balanceSheetList = (request) => ({
  payload: request,
  type: BALANCE_SHEET_ARRAY,
});
export const removeLastItem  = (request) => ({
    payload: request,
    type: REMOVE_LAST_ITEM,
  });
  export const clearArray  = (request) => ({
    payload: request,
    type: CLEAR_ARRAY,
  });